import React, {FC, useEffect, useState} from 'react';
import {Col, Container, Form, Row} from 'react-bootstrap';
import {DragDropContext, Droppable, Draggable, DropResult} from 'react-beautiful-dnd';

import {useGetPresetsQuery, useGetSiteConfigQuery} from "../../redux/api/slothAPI";
import {capitalizeString, convertPresetName, isMobile} from "../../utils/utils";
import {Link, useParams} from "react-router-dom";
import PresetSelectorModal from "./PresetSelectorModal";
import {CustomListGroup} from "../../styles/Sites/SiteView";
import {Badge} from "../../styles/CommonComponents";
import {Button} from "../../styles/Buttons";
import PermissionButton from "../ButtonWithPermission";

const PresetSelector: FC = () => {

    const routerParams = useParams();

    const queryArgs: any = {
        id: routerParams?.siteId
    }

    const [showModal, setShowModal] = useState(false);

    const [appliedPresets, setAppliedPresets] = useState<string[] | undefined>(undefined)
    const [notAppliedPresets, setNotAppliedPresets] = useState<string[] | undefined>(undefined)
    const [searchInput, setSearchInput] = useState('');

    const {data: presets} = useGetPresetsQuery()
    const {
        data: siteConfig
    } = useGetSiteConfigQuery(queryArgs)

    // Function to handle drag end
    const onDragEnd = (result: DropResult) => {
        if (!result.destination || isMobile()) return;

        // Provide a default empty array if appliedPresets is undefined
        const items = Array.from(appliedPresets || []);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);

        setAppliedPresets(items);
    };


    useEffect(() => {
        if (siteConfig && siteConfig.presets) {
            const applied = siteConfig.presets;
            setAppliedPresets(applied);
        } else {
            // If siteConfig or siteConfig.presets is not present, reset applied presets to an empty array.
            setAppliedPresets([]);
        }
    }, [siteConfig]);

    useEffect(() => {
        if (presets && appliedPresets) {
            const notApplied = presets.results
                .map((preset) => preset.name)
                .filter((presetName) => !appliedPresets.includes(presetName))
                .filter((presetName) => //add to lower case 
                    presetName.toLowerCase().includes(searchInput.toLowerCase())
                );


            setNotAppliedPresets(notApplied);
        }
    }, [presets, appliedPresets, searchInput]);

    


    const applyPreset = (id: string) => {
        // remove id from notApplied to applied

        let newNotApplied = notAppliedPresets && notAppliedPresets?.filter((preset) => preset !== id)
        setNotAppliedPresets(newNotApplied)
        // add id to applied

        let newApplied = appliedPresets || []
        //bundle.js:3687 Uncaught TypeError: Cannot add property 3, object is not extensible
        newApplied = [...newApplied, id];
        setAppliedPresets(newApplied)
        setSearchInput("")
    }
    const removePreset = (id: string) => {
        // remove id from applied to notApplied
        let newApplied = appliedPresets && appliedPresets?.filter((preset) => preset !== id)
        setAppliedPresets(newApplied)
        // add id to notApplied
        let newNotApplied = notAppliedPresets
        newNotApplied?.push(id)
        setNotAppliedPresets(newNotApplied)
        setSearchInput("")
    }

    const handleUpClick = (preset: string) => {
        if (appliedPresets) {
            const currentIndex = appliedPresets.indexOf(preset);

            if (currentIndex > 0) {
                // Ensure the item is not at the beginning of the list
                const updatedArray = [...appliedPresets];
                // Swap the item with the one above it
                [updatedArray[currentIndex], updatedArray[currentIndex - 1]] = [
                    updatedArray[currentIndex - 1],
                    updatedArray[currentIndex]
                ];
                setAppliedPresets(updatedArray);
            }
        }
    };
    const handleDownClick = (preset: string) => {
        if (appliedPresets) {
            const currentIndex = appliedPresets.indexOf(preset);

            if (currentIndex < appliedPresets.length - 1) {
                // Ensure the item is not at the end of the list
                const updatedArray = [...appliedPresets];
                // Swap the item with the one below it
                [updatedArray[currentIndex], updatedArray[currentIndex + 1]] = [
                    updatedArray[currentIndex + 1],
                    updatedArray[currentIndex]
                ];
                setAppliedPresets(updatedArray);
            }
        }
    };

    return (
        <Container fluid>
            <Row>
                <Col sm={12} md={12} lg={12} xs={12} className={"d-flex justify-content-end"}>
                    <PermissionButton variant="success" onClick={() => setShowModal(true)} className={"m-2"}>Save Changes</PermissionButton>
                </Col>
            </Row>
            <Row className="d-flex align-items-center">
                <Col className="col-auto">
                </Col>
                <Col className="flex-grow-1">
                    <h4> Active Presets <Badge backgroundcolor="white">{appliedPresets && appliedPresets.length}</Badge></h4>
                </Col>
            </Row>
            <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="appliedPresets">
                    {(provided) => (
                        <CustomListGroup {...provided.droppableProps} ref={provided.innerRef}>
                            {appliedPresets && appliedPresets.length > 0 ? (
                                appliedPresets.map((preset, index) => (
                                    <Draggable key={preset} draggableId={preset} index={index} isDragDisabled={isMobile()}>
                                        {(provided) => (
                                            <CustomListGroup.Item
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                                className={"mt-1"}>
                                                <Row className="d-flex align-items-center">
                                                    {!isMobile() && (
                                                        <Col className="col-auto">
                                                            <i className="bi bi-grip-vertical"></i>
                                                        </Col>
                                                    )}
                                                    <Col className="flex-grow-1">
                                                        <Link to={"/presets/" + preset + "/configurator"} style={{color: "black"}} target="_blank">
                                                            {capitalizeString(convertPresetName(preset))}
                                                        </Link>
                                                    </Col>
                                                    {isMobile() && (
                                                        <Col className="col-auto ms-auto">
                                                            <div className="gap-2 m-1">

                                                                <Button onClick={() => handleUpClick(preset)} variant="outline-secondary">
                                                                    <i className="bi-chevron-up"></i>
                                                                </Button>
                                                                <Button onClick={() => handleDownClick(preset)} variant="outline-secondary">
                                                                    <i className="bi-chevron-down"></i>
                                                                </Button>
                                                            </div>
                                                        </Col>
                                                    )}
                                                    <Col className="col-auto ms-auto">
                                                        <Button onClick={() => removePreset(preset)} variant="outline-secondary">Set As Inactive</Button>
                                                    </Col>
                                                </Row>
                                            </CustomListGroup.Item>
                                        )}
                                    </Draggable>
                                ))
                            ) : (
                                <p>No Presets found</p>
                            )}
                            {provided.placeholder}
                        </CustomListGroup>
                    )}
                </Droppable>
            </DragDropContext>


            <hr/>
            <Row className={" mt-1"}>
                <Col>
                    <div className={"m-2"}>
                        <h4>Inactive Presets <Badge backgroundcolor="white">{notAppliedPresets && notAppliedPresets.length}</Badge></h4>
                    </div>
                </Col>
                <Col lg={3}>
                    {
                        notAppliedPresets && notAppliedPresets.length > 0 &&
                        <div className={"m-2"}>
                          <Form.Control
                            type="text"
                            placeholder="search"
                            value={searchInput}
                            onChange={(e) => setSearchInput(e.target.value)} // Update the state on change
                          />
                        </div>}
                </Col>
            </Row>


            <CustomListGroup>
                {(notAppliedPresets && notAppliedPresets.length > 0 && notAppliedPresets.map((preset, index) => {
                    return (
                        <CustomListGroup.Item key={preset} className={"mt-1"}>
                            <Row>
                                <Col sm={10} lg={10} md={10} xs={10} className={"d-flex align-items-center"}>
                                    <Link to={"/presets/" + preset + "/configurator"} style={{color: "black"}} target="_blank">
                                        {capitalizeString(convertPresetName(preset))}
                                    </Link>
                                </Col>
                                <Col sm={2} md={2} lg={2} xs={2} className="d-flex align-items-center justify-content-end">
                                    <div className="gap-2">
                                        <Button onClick={() => applyPreset(preset)} variant="success"><i className="bi bi-plus"></i>Apply Preset</Button>
                                    </div>
                                </Col>
                            </Row>
                        </CustomListGroup.Item>
                    )
                }))
                ||
                <p>
                  No Presets found
                </p>
                }
            </CustomListGroup>

            <PresetSelectorModal showModal={showModal} setShowModal={setShowModal} presets={appliedPresets}/>


        </Container>
    );


};

//@ts-ignore
/*PresetSelector.whyDidYouRender = true;*/
export default PresetSelector;