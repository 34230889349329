import React, {FC} from 'react';
import {IHomeNavigation} from "../../types";

//This is the second navigation shown in the sites overview containing search
const SitesNavigation: FC<IHomeNavigation> = ({setViewAsList, setViewAsCards}) => {
    return (
            <>
                <span
                onClick={setViewAsList}
                className={"bi bi-list"}
                style={{
                    fontSize : "28px", // Adjust the font size as needed for the desired icon size
                    cursor: 'pointer', // Change the cursor to a pointer
                }}
            ></span>
                <span
                    onClick={setViewAsCards}
                    className={"bi bi-grid-3x3 m-2"}
                    style={{
                        fontSize: '22px', // Adjust the font size as needed for the desired icon size
                        cursor: 'pointer', // Change the cursor to a pointer
                    }}
                ></span>
            </>
    );
};

export default SitesNavigation;


