import React, {FC, KeyboardEvent, useEffect, useState} from 'react';
import {Row, Col, Form} from "react-bootstrap"
import {Badge} from "../../styles/CommonComponents";
import Loader from "../Loader";
import AddInventory from "./AddInventory";
import ConfigNavigation from "../configuration/ConfigNavigation";
import {useConfiguratorContext} from "../../hooks/configuratorContext";
import ContextModules from "../configuration/context/ContextModules";
import {useFormContext} from "react-hook-form";

interface ISiteConfiguration {
    location: string;

}

const InventoryConfig: FC<ISiteConfiguration> = ({location}) => {
    // Getting site ID from route and RTK query hooks
    const {
        slots,
        inactiveSlots,
        onlyPresetSlots,
        addSlot,
        deleteSlot,
        localInventoryConfig
    } = useConfiguratorContext()
    const methods = useFormContext();
    const [selectedSlots, setSelectedSlots] = useState<string[]>([]);
    const [editingInventory, setEditingInventory] = useState<{ [key: string]: boolean }>({}); // Track editing state for each inventory item
    const [inventoryDescriptions, setInventoryDescriptions] = useState<{ [key: string]: string }>({}); // Track updated descriptions

    //create a useeffect that takes the descriptions from the localInventoryConfig and sets them to the inventoryDescriptions
    useEffect(() => {
        if (localInventoryConfig) {
            const descriptions: { [key: string]: string } = {};
            Object.keys(localInventoryConfig.inventoryConfig).forEach((inventory) => {
                descriptions[inventory] = localInventoryConfig?.inventoryConfig[inventory]?.meta?.description;
            });
            setInventoryDescriptions(descriptions);
        }
    }, [localInventoryConfig]);


    // Inside your component where handleSelect is defined

    // Toggle editing mode for a specific inventory
    const toggleEditing = (inventory: string) => {
        setEditingInventory((prevState) => ({
            ...prevState,
            [inventory]: !prevState[inventory],
        }));
    };

    // Handle description change
    const handleDescriptionChange = (inventory: string, value: string) => {
        setInventoryDescriptions((prevState) => ({
            ...prevState,
            [inventory]: value,
        }));
        methods.setValue("Inventory_Descriptions." + inventory, value)
    };
    // Save the new description when "Enter" is pressed or check icon is clicked
    const handleSaveDescription = (inventory: string) => {
        // Save description logic (e.g., update state, send to API, etc.)
        toggleEditing(inventory); // Exit edit mode after saving
    };

    const handleSelectedSlots = (slot: string) => {
        setSelectedSlots((prevSelectedSlots) => {
            if (prevSelectedSlots.includes(slot)) {
                return prevSelectedSlots.filter((s) => s !== slot);
            } else {
                return [...prevSelectedSlots, slot];
            }
        });
    };

    const handleKeyPress = (e: KeyboardEvent, inventory: string) => {
        if (e.key === 'Enter') {
            handleSaveDescription(inventory);
        }
    };


    return (
        <>
            {
                (slots &&
                    <Row>
                        <Col xs={12} lg={3}>
                            <ConfigNavigation elements={structuredClone(slots)}
                                              inactiveElements={inactiveSlots}
                                              deleteElement={deleteSlot}
                                              addElement={addSlot}
                                              selectedElements={selectedSlots}
                                              handleSelect={handleSelectedSlots}
                                              inventoryConfig={localInventoryConfig}
                                              inventoryDescriptions={inventoryDescriptions}
                            />
                            <AddInventory addSlot={addSlot} inventory={slots}/>
                        </Col>
                        <Col xs={12} lg={9}>
                            <div className={"sloth-box"}>
                                {
                                    slots && slots.map((inventory: string, index: number) => {
                                        return (
                                            <div className="align-items-center mt-2" id={inventory} key={inventory + index}
                                                 style={{
                                                     display: selectedSlots.length === 0 || selectedSlots.includes(inventory) ? 'block' : 'none'
                                                 }}>
                                                <Row>
                                                    <h5>
                                                        <b>{inventory}</b>{" "}
                                                        {onlyPresetSlots && onlyPresetSlots.includes(inventory) && (
                                                            <Badge className="ml-2">preset</Badge>
                                                        )}

                                                        {!onlyPresetSlots?.includes(inventory) && editingInventory[inventory] ? (
                                                            <>
                                                                {/* Show Input Field in Edit Mode */}
                                                                <Form.Control
                                                                    {...methods.register("Inventory_Descriptions." + inventory)}
                                                                    value={inventoryDescriptions[inventory] || ""}
                                                                    onChange={(e) => handleDescriptionChange(inventory, e.target.value)}
                                                                    onKeyPress={(e) => handleKeyPress(e, inventory)} // Handle Enter key
                                                                    type={"text"}
                                                                    style={{
                                                                        display: "inline-block",
                                                                        width: "20%",
                                                                        marginLeft: "10px"
                                                                    }} // Keep input inline
                                                                />
                                                                {/* Check Icon to Save */}
                                                                <span
                                                                    className="bi bi-check-circle m-2"
                                                                    onClick={() => handleSaveDescription(inventory)}
                                                                    style={{cursor: "pointer"}}
                                                                ></span>
                                                            </>
                                                        ) : (
                                                            !onlyPresetSlots?.includes(inventory) && (
                                                                <>
                                                                    {/* Show Description in View Mode */}
                                                                    <span className="text-muted description">
                {" (" +
                    (inventoryDescriptions[inventory] || "no description available") +
                    ")"}
            </span>
                                                                    {/* Pencil Icon to Edit */}
                                                                    <span
                                                                        className="bi bi-pencil-square ml-2"
                                                                        onClick={() => toggleEditing(inventory)}
                                                                        style={{cursor: "pointer"}}
                                                                    ></span>
                                                                </>
                                                            )
                                                        )}
                                                    </h5>
                                                </Row>
                                                <ContextModules groupId={inventory} location={location}/>
                                            </div>
                                        );
                                    })
                                }
                            </div>
                        </Col>
                    </Row>
                ) || <Loader/>
            }
        </>
    );
};

//@ts-ignore
InventoryConfig.whyDidYouRender = false;
export default InventoryConfig;




