import React, {FC} from 'react';
import Badge from "react-bootstrap/Badge";
import ConfigChanges from "../configuration/ConfigChanges";


interface IInventoryChanges {
    inventoryChanges: any
    presetsChanges ?: any
}

const InventoryChanges: FC<IInventoryChanges> = ({inventoryChanges , presetsChanges}) => {

    return (
        <>
            {
                inventoryChanges && inventoryChanges.length > 0 &&
                    <>
                        {
                            inventoryChanges.map((
                                inventoryChange: any, index: any
                            ) => {
                                let inventory = Object.keys(inventoryChange)[0]
                                return (
                                    <div className={"m-2"} key={inventory + index}>
                                        {
                                            (inventoryChange[inventory] === "new" &&
                                                <h5>{inventory} <Badge bg="success">New slot</Badge></h5>)
                                            ||
                                            (inventoryChange[inventory] === "delete" &&
                                                <h5> {inventory} <Badge bg="danger">deleted</Badge></h5>)
                                            ||
                                            (inventoryChange[inventory] === "reset" &&
                                                <h5> {inventory} <Badge bg="primary">reset</Badge></h5>
                                            )
                                                ||
                                            (
                                                inventoryChange[inventory].length > 0 &&
                                                <>
                                                    <h5> {inventoryChange[inventory][0].type !== "description" &&  inventory} </h5>
                                                    <ConfigChanges configChanges={inventoryChange[inventory]} isConfigChange={true} presetsChanges={presetsChanges}/>
                                                </>
                                            )
                                        }
                                    </div>

                                )
                            })
                        }
                    </>

            }
        </>
    );
};




export default InventoryChanges;




