import React, { FC, useState, useMemo, useCallback } from "react";
import { Row, Col, Container, Stack } from "react-bootstrap";
import { useGetPublishersQuery } from "../../redux/api/slothAPI";
import { convertPublisherName } from "../../utils/utils";
import CreatePublisherModal from "./CreatePublisherModal";
import { Link } from "react-router-dom";
import { Badge } from "../../styles/CommonComponents";
import { Button } from "../../styles/Buttons";
import { CustomListGroup } from "../../styles/Sites/SiteView";
import PermissionButton from "../ButtonWithPermission";
import Loader from "../Loader";
import SearchBar from "../SearchBar";

interface IPublisher {
    name: string;
    // Add other properties if necessary
}

const Publishers: FC = () => {
    const { data, isLoading, isError } = useGetPublishersQuery();
    const [searchInput, setSearchInput] = useState('');
    const [showModalPublisher, setShowModalPublisher] = useState(false);

    const normalizeString = useCallback((str: string): string => {
        return str.toLowerCase().replace(/[^a-z0-9]/g, '');
    }, []);

    const filteredPublishers = useMemo(() => {
        if (!data?.results) return [];
        const normalizedSearchInput = normalizeString(searchInput);
        return data.results.filter((publisher: IPublisher) =>
            normalizeString(publisher.name).includes(normalizedSearchInput)
        );
    }, [data, searchInput, normalizeString]);

    const toggleModal = () => setShowModalPublisher(!showModalPublisher);

    const handleSearch = (searchValue: string) => {
        setSearchInput(searchValue);
    };

    if (isLoading) {
        return <Loader />;
    }

    return (
        <>
            <Container fluid className="mb-5">
                <Row>
                    <Col className="mt-4 mb-4">
                        <Stack direction="horizontal" gap={1}>
                            <h3>Publishers</h3>
                            <Badge className={"mb-1"} bg="secondary">{filteredPublishers.length}</Badge>
                        </Stack>
                    </Col>
                    <Col className="text-end align-content-end mt-4 mb-4">
                        <PermissionButton variant="success" onClick={toggleModal}>
                            <i className="bi bi-plus"></i>Add New Publisher
                        </PermissionButton>
                    </Col>
                </Row>
                <div className="mt-2">
                    <Row className="mt-1">
                        <Col lg={4} md={12} xs={12}>
                            <div className="mt-2 mb-4">
                                <SearchBar
                                    onSearch={handleSearch}
                                    placeholder="Search for publisher"
                                    initialValue={searchInput}
                                />
                            </div>
                        </Col>
                    </Row>
                </div>

                {isError ? (
                    <div className="text-center mt-5">
                        <p>Error loading publishers</p>
                    </div>
                ) : filteredPublishers.length > 0 ? (
                    filteredPublishers.map((publisher: IPublisher, index: number) => (
                        <CustomListGroup key={index + publisher.name}>
                            <CustomListGroup.Item>
                                <Link
                                    style={{color: 'black', display: 'block', width: '100%', textAlign: 'left'}}
                                    to={"/sites?search=" + convertPublisherName(publisher.name)}
                                >
                                    <Row>
                                        <Col xs={9} className={"d-flex align-items-center"}>
                                            {convertPublisherName(publisher.name)}
                                        </Col>
                                        <Col xs={3} className="d-flex justify-content-end align-items-center">
                                            <Button variant="primary"><i className="bi bi-arrow-up-right"></i></Button>
                                        </Col>
                                    </Row>
                                </Link>
                            </CustomListGroup.Item>
                        </CustomListGroup>
                    ))
                ) : (
                    <div className="text-center mt-5">
                        <p>No publishers found</p>
                    </div>
                )}
            </Container>
            <CreatePublisherModal show={showModalPublisher} onHide={toggleModal} />
        </>
    );
};

export default Publishers;