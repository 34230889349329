import {createContext, useContext} from "react";

export interface ConfiguratorContextType {
    builderConfig?: any;
    localModuleConfig?: any;
    moduleGroups ?: string[];
    location?: string ;
    localInventoryConfig?: any;
    slots ?: string[];
    inactiveSlots ?: string[];
    onlyPresetSlots ?: string[];
    mergedConfig?: any;
    deleteSlot?: (slot: string) => void;
    addSlot: (slot: string) => void;
    addToLocalModuleConfig?: (moduleName: string, variableName: string, value: any, formIdentifier: string) => void;
    addToLocalInventoryConfig?: (moduleName: string, variableName: string, slot: string, value: any, formIdentifier: string) => void;
    unsetVariable?: (module: string, variable: string, location: string, inventory: string, formIdentifier: string) => void;
}

export const ConfiguratorContext = createContext<ConfiguratorContextType>({
    builderConfig: {},
    location: "",
    moduleGroups: [],
    localModuleConfig: {},
    localInventoryConfig: {},
    slots: [],
    inactiveSlots: [],
    onlyPresetSlots: [],
    mergedConfig: {},

    deleteSlot: () => {

    },
    addSlot: () => {

    },

    addToLocalModuleConfig: () => {
    },
    addToLocalInventoryConfig: () => {
    },
    unsetVariable: () => {
    }
});


export const useConfiguratorContext = () => useContext(ConfiguratorContext)
