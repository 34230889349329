import React from 'react';
import { Link } from 'react-router-dom';
import { HomePageWrapper } from '../../styles/HomePage';
import { Container, Row, Col, Card } from 'react-bootstrap';
import { Button } from 'react-bootstrap';

const HomePage: React.FC = () => {
    const renderCard = (title: string, path: string) => (
        <Col md={4} key={title}>
            <Link to={path}>
                <Card className="mb-4">
                    <Card.Body>
                        <Card.Title><h3>{title}</h3></Card.Title>
                        <Button variant="primary">View {title}</Button>
                    </Card.Body>
                </Card>
            </Link>
        </Col>
    );

    return (
        <HomePageWrapper>
            <div className="welcome-section">
                <div className="sloth-image">
                    <img src="/images/slothHome.png" alt="Sloth" />
                </div>
            </div>
            <Container className="mt-4">
                <Row>
                    {renderCard('Sites', '/sites')}
                    {renderCard('Presets', '/presets')}
                    {renderCard('Publishers', '/publishers')}
                </Row>
            </Container>
        </HomePageWrapper>
    );
};

export default HomePage;