import React, {FC} from 'react';
import {OverlayTrigger, Tooltip} from "react-bootstrap";

interface IDescription {
    description: string
}
const Description: FC<IDescription> = ({description}) => {
    return (
        <OverlayTrigger
            placement="auto"
            overlay={<Tooltip>
                {description || "there is no description"}
            </Tooltip>}>
            <span className="me-2 bi bi-question-circle"/>
        </OverlayTrigger>
    );
};
export default Description;