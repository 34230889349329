import React, {FC} from 'react';
import {Modal,Button} from "react-bootstrap";
import {useGetConfigHistoryQuery } from "../../redux/api/slothAPI";
import Loader from "../Loader"
import {convertDate} from "../../utils/utils";

import HistoryAccordion from "./HistoryAccordion";

interface HistoryProps {
    showHistory: boolean;
    setShowHistory: () => void;
    configId?: string
    service  : string
}

const HistoryModal: FC<HistoryProps> = ({showHistory, setShowHistory, configId , service}) => {



    const {data : history, isLoading} = useGetConfigHistoryQuery({
        id: configId,
        service: service
    }  )

    return (
        <>
            <Modal
                show={showHistory}
                onHide={setShowHistory}
                size="xl"

                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title> This is the history of {configId} </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {isLoading && <Loader/> }
                    {history && history.results && history.results.map(({ commitId, commitMessage, date }: { commitId: string, commitMessage: string, date: string }, index : any) => {
                        let previousCommitId;

                        if (index < history.results.length - 1 ) {
                            previousCommitId = history.results[index +1].commitId;
                        }
                        if(previousCommitId){
                            try{
                                let parsedCommitMessage = JSON.parse(commitMessage)
                                return (
                                    <HistoryAccordion service = {service} key={commitId + index} commitId={commitId} previousCommitId={previousCommitId} commitMessage={parsedCommitMessage.message} message={parsedCommitMessage && parsedCommitMessage.email + " has commited a change on " + convertDate(date)} />
                                );
                            }
                            catch{
                                return (
                                    <HistoryAccordion service = {service} key={commitId + index} commitId={commitId} previousCommitId={previousCommitId} message={commitMessage} />
                                );
                            }
                        }
                    })}
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={setShowHistory} variant="secondary">
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default HistoryModal;