import styled from 'styled-components';

export const SearchBarWrapper = styled.div`

  button {
    background: none;
    border: none;
    color: black;
    position: absolute !important;
    right: 50px;
    font-size: 20px;
    padding: 0;
    top: 3px;
    z-index: 10 !important;
  }

  button:hover {
    background: none;
    color: black;
  }

`;