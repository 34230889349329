import React from 'react';
import { Modal, Button } from 'react-bootstrap';

interface NavigationModalProps {
    navigationModalVisible: boolean;
    setNavigationModalVisible: () => void;
    newPath: string;
    onConfirm: () => void;
}

const NavigationModal: React.FC<NavigationModalProps> = ({
                                                             navigationModalVisible,
                                                             setNavigationModalVisible,
                                                             newPath,
                                                             onConfirm,
                                                         }) => (
    <Modal
        show={navigationModalVisible}
        onHide={setNavigationModalVisible}

        aria-labelledby="navigation-modal"
    >
        <Modal.Header closeButton>
            <Modal.Title>Unsaved Changes</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <p>You have unsaved changes. Proceeding will discard them!</p>
        </Modal.Body>
        <Modal.Footer>
            <Button variant="secondary" onClick={setNavigationModalVisible}>
                Cancel
            </Button>
            <Button variant="primary" onClick={onConfirm}>
                Proceed
            </Button>
        </Modal.Footer>
    </Modal>
);

export default NavigationModal;
