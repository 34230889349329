import {FC} from "react";
import {Modal, Button} from "react-bootstrap";
import {useParams} from "react-router-dom";
import Loader from "../Loader";
import {usePutSiteConfigMutation} from "../../redux/api/slothAPI";

interface IPresetSelectorModal {
    showModal: boolean;
    setShowModal: (showModal: boolean) => void;

    presets: string[] | undefined;


}

const PresetSelectorModal: FC<IPresetSelectorModal> = ({
                                                           showModal,
                                                           setShowModal,
                                                           presets,

                                                       }) => {
    const routerParams = useParams();
    const queryArgs: any = {
        id: routerParams?.siteId
    }
    const [postConfig, {
        isLoading: postConfigIsLoading,
        isSuccess,
        isError: isPostConfigError,
        reset
    }] = usePutSiteConfigMutation();
    const saveChanges = () => {

        postConfig({
            id: queryArgs.id,
            data: {
                presets: presets,
                version: "2.0",
                message: ""
            }
        }).unwrap()
            .catch((rejectedValueOrSerializedError) => {
                    alert("error" + rejectedValueOrSerializedError)
                }
            )
    }
    return (
        <>
            <Modal show={showModal} onHide={() => {
                setShowModal(false)
                reset()
            }}>
                <Modal.Header closeButton>
                    <Modal.Title>Save Preset Order</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {
                        (postConfigIsLoading && <Loader/>) ||
                        (isPostConfigError && <div> Error applying presets</div>) ||
                        (isSuccess && <div> Changes saved</div>) ||
                        (presets && presets.length > 0 && presets.map((preset) => {
                            return (
                                <div key={preset}>
                                    {preset}
                                </div>
                            )
                        }))
                        ||
                        <div>
                            No presets applied to this site
                        </div>
                    }
                </Modal.Body>
                <Modal.Footer>
                    <Button variant={"secondary"} onClick={() => {
                        setShowModal(false)
                        reset()
                    }}>
                        Close
                    </Button>
                    {
                         !isPostConfigError && !isSuccess &&
                        <Button variant={"primary"} onClick={() => {
                            saveChanges()
                        }
                        }> Save Changes </Button>
                    }
                </Modal.Footer>
            </Modal>
        </>
    );

}

export default PresetSelectorModal;