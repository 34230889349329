import React, { useState, useCallback } from "react";
import { Form, InputGroup, Button } from "react-bootstrap";
import { debounce } from "lodash";
import { SearchBarWrapper } from "../styles/SearchBar";

interface SearchProps {
    onSearch: (searchValue: string) => void;
    initialValue?: string;
    placeholder?: string;
}

const SearchBar: React.FC<SearchProps> = ({
                                              onSearch,
                                              initialValue = "",
                                              placeholder = "Search",
                                          }) => {
    const [searchInput, setSearchInput] = useState(initialValue);

    const normalizeString = (str: string): string => {
        return str.toLowerCase().replace(/[^a-z0-9]/g, '');
    };

    const debouncedSearch = useCallback(
        debounce((searchValue: string) => {
            const normalizedSearch = normalizeString(searchValue);
            onSearch(normalizedSearch);
        }, 300),
        [onSearch]
    );

    const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        setSearchInput(value);
        debouncedSearch(value);
    };

    const clearSearch = () => {
        setSearchInput("");
        onSearch("");
    };

    return (
        <SearchBarWrapper className="mt-2 mb-4">
            <InputGroup>
                <Form.Control
                    type="text"
                    placeholder={placeholder}
                    value={searchInput}
                    onChange={handleSearchChange}
                />
                {searchInput && (
                    <Button onClick={clearSearch}>
                        <i className="bi bi-x"></i>
                    </Button>
                )}
                <InputGroup.Text>
                    <i className="bi bi-search"></i>
                </InputGroup.Text>
            </InputGroup>
        </SearchBarWrapper>
    );
};

export default SearchBar;