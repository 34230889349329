import styled from 'styled-components';
import {ListGroup, Col} from 'react-bootstrap';
import {robotoFontStyle} from '../Fonts';


export const ListViewHeader = styled(Col)`
  padding: 10px 0;
  background-color: #fff;
  position: sticky;
  top: 79px;
  z-index: 1;

  border-radius: 4px 4px 0px 0px;
  border: 1px solid #E5E4E2;
  padding: 10px;
`
export const ListViewWrapper = styled.div`
  > :first-child .list-group-item {
    border-radius: 4px 4px 0px 0px;
    border-top: 1px solid #E5E4E2;
  }

  > :last-child .list-group-item {
    border-radius: 0px 0px 4px 4px;
  }
`;


export const CustomListGroup = styled(ListGroup)`

  ${robotoFontStyle}
  div.list-group-item {
    border-radius: 0px;
    border-top: none;
    border-bottom: 1px solid #E5E4E2;
    padding-top: 0;
    padding-bottom: 0;
  }

  .list-group-item a {
    color: #152536 !important;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding: 25px 0;
    width: 100%;
    display: block;
  }

`;




