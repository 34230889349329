import React, {FC, useState} from 'react';
import {Modal, Button, Form, Spinner} from "react-bootstrap";
import {ModuleDifference} from "../../types";
import ConfigChanges from "./ConfigChanges";
import InventoryChanges from "../inventory/InventoryChanges";
import {systemChangeTypes} from "../../utils/utils";
interface ConfigModalsProps {
    progressModalVisible: boolean;
    changeProgressModalVisibility: () => void;
    confirmationModalVisible: boolean;
    changeConfirmationModalVisibility: () => void;
    saveChanges: (message: string) => void;
    configChanges: ModuleDifference[] | any
    inventoryChanges?: ModuleDifference[] | any
    statusMessage: string
    isLoading: boolean
    configType?: string
    setDeletedPresetSlots?: () => void
    setConfigChanges?: () => void
}

const ConfigModals: FC<ConfigModalsProps> = ({
                                                 progressModalVisible,
                                                 changeProgressModalVisibility,
                                                 confirmationModalVisible,
                                                 changeConfirmationModalVisibility,
                                                 saveChanges,
                                                 configChanges,
                                                 inventoryChanges,
                                                 statusMessage,
                                                 isLoading,
                                                 configType,
                                                 setDeletedPresetSlots,
                                                 setConfigChanges

                                             }) => {

    const [message, setMessage] = useState('');

    const handleInputChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        setMessage(event.target.value);
    }



    const isOnlySystemChanges = configChanges.filter((change: ModuleDifference) => {
            return (change.type && systemChangeTypes.includes(change.type));
        }
    ).length === configChanges.length;



    const handleSaveChanges = () => {
        setDeletedPresetSlots && setDeletedPresetSlots();
        setConfigChanges && setConfigChanges();
        saveChanges(message);
    }

    return (
        <>
            {/* Modal for confirmation and changes */}
            <Modal
                show={confirmationModalVisible}
                onHide={changeConfirmationModalVisibility}
                dialogClassName="modal-90w"
                aria-labelledby="example-custom-modal-styling-title"
            >
                <Modal.Header closeButton>
                    <Modal.Title> Do you want to save the following changes ? </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {(!configType &&
                        <ConfigChanges configChanges={configChanges} isConfigChange={true} presetsChanges={[]}/>)}
                    {(configType === "inventory" && <InventoryChanges inventoryChanges={configChanges}/>)}
                    {
                        (configType === "context" &&
                        <>
                            <ConfigChanges configChanges={configChanges} isConfigChange={true} presetsChanges={[]} inventoryChanges={inventoryChanges} />
                            <InventoryChanges inventoryChanges={inventoryChanges}/>
                        </>
                        )
                    }



                    {((!isOnlySystemChanges || configType === "context")  && (configChanges.length > 0 || (inventoryChanges?.length > 0))  &&
                        <div className={"mt-5"}>
                            <Form.Group className="mb-3" controlId="commitMessage">
                                <Form.Label><b>Comment</b></Form.Label>
                                <Form.Control as="textarea" rows={3} placeholder="optional message / ticket number"
                                              onChange={handleInputChange}/>
                            </Form.Group>
                        </div>
                    )
                    }
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={changeConfirmationModalVisibility}>
                        Close
                    </Button>
                    {
                        ((!isOnlySystemChanges || configType === "context") && (configChanges.length > 0 || inventoryChanges.length > 0) &&
                            <Button variant="primary" onClick={handleSaveChanges}>
                                Save Changes
                            </Button>)
                    }
                </Modal.Footer>
            </Modal>
            {/* Modal for save progress */}
            <Modal
                show={progressModalVisible}
                onHide={changeProgressModalVisibility}

                keyboard={false}
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title> Saving progress </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {(isLoading && <Spinner animation="border" variant="primary"/>) ||
                        statusMessage}
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={changeProgressModalVisibility} variant="secondary">
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default ConfigModals;