import React, {FC, useMemo, useState} from 'react';
import {useParams} from "react-router-dom";
import {useGetSiteConfigQuery, useGetPresetConfigsQuery} from "../../redux/api/slothAPI";
import TagGenerator from "./TagGenerator";
import Loader from "../Loader";
import {isEnvironment} from "../../utils/utils";
import {mergeInventoryConfig} from "../../utils/config-helper";
import TagOptionsModal from "./TagOptionsModal";
import {Row , Col} from "react-bootstrap";
import {Button} from "react-bootstrap";

const TagInTag: FC = () => {

    const routerParams = useParams();

    const queryArgs = useMemo(() => ({
        id: routerParams?.siteId
    }), [routerParams?.siteId]);

    const environment = isEnvironment("prod") ? "prod" : "stage"
    const {data, isLoading} = useGetSiteConfigQuery(queryArgs)
    const {data: appliedPresetsInventoryConfig} = useGetPresetConfigsQuery(data?.presets)

    const [show, setShow] = useState(false);
    const [tagOptions, setTagOptions] = useState("");
    const setModalShow = () => {

        setShow(!show);
    }

    const mergedPresetsInventoryConfig = useMemo(() => {
        return mergeInventoryConfig(appliedPresetsInventoryConfig || []);
    }, [appliedPresetsInventoryConfig]);
    const mergedInventoryConfig = useMemo(() => {
        // Start with all keys from data
        const merged = {...data?.inventoryConfig};
        // Add keys from test1 that are not in data
        Object.keys(mergedPresetsInventoryConfig).forEach(key => {
            if (!(key in data?.inventoryConfig)) {
                merged[key] = mergedPresetsInventoryConfig[key];
            }
        });
        // Remove keys with null values
        Object.keys(merged).forEach(key => {
            if (merged[key] === null) {
                delete merged[key];
            }
        });
        return merged;
    }, [mergedPresetsInventoryConfig, data?.inventoryConfig]);

    // Extracting inventory keys from the merged configuration
    const inventoryKeys = mergedInventoryConfig && Object.keys(mergedInventoryConfig)

    return (
        <>
            <Row>
                <Col lg={2}>
                    <Button onClick={setModalShow}> <i className="bi bi-plus"></i>Tag Options</Button>
                </Col>

            </Row>

            <div className="mb-3">
                <TagGenerator siteName={queryArgs.id} rows={1} environment={environment} tagOptions={tagOptions}/>
            </div>
            {isLoading && <Loader/>}
            {inventoryKeys && inventoryKeys.map((inventory: string, index: number) => {
                    return (
                        <div key={inventory} className="mb-3">
                            <h4>
                                {inventory}
                                {mergedInventoryConfig[inventory]?.meta?.description && (
                                    <div className="text-muted" style={{ display: 'inline-block', marginLeft: '10px', fontSize: '1.3rem'}}>
                                       ({mergedInventoryConfig[inventory].meta.description})
                                    </div>
                                )}
                            </h4>
                            <TagGenerator siteName={queryArgs.id} inventory={inventory} rows={3} environment={environment} tagOptions={tagOptions}/>
                        </div>
                    )
                }
            )}
            <TagOptionsModal tagOptions={tagOptions} setTagOptions= {setTagOptions} show={show} setShow={setModalShow} />
        </>
    );
};

export default TagInTag;




