import React, { FC, useState, useEffect, useCallback } from "react";
import SiteCard from "./SiteCard";
import SitesNavigation from "./SitesNavigation";
import Loader from "../Loader";
import { Row, Col, Container, Stack } from "react-bootstrap";
import { capitalizeString } from "../../utils/utils";
import { useGetSitesQuery } from "../../redux/api/slothAPI";
import AddSite from "./AddSite";
import SiteListElement from "./SiteListElement";
import SearchBar from "../SearchBar";
import GbucketSync from "./GbucketSync";
import { Badge } from "../../styles/CommonComponents";
import { ListViewWrapper, ListViewHeader } from "../../styles/Sites/SiteView";
import { useEnvConfig } from "../../redux/hooks";
import FilterInput from "../../components/filter/filter"
import { AppliedFilter } from '../../components/filter/filter';



interface Site {
    id: string;
    name: string;
    publisher: string;
    displayName?: string;
    stats?: {
        logs: {
            errorRate: number;
            fillRate: number;
        };
    };
    moduleConfig?: Record<string, any>;
    presets?: string[];
    loaderConfig?: {
        page?: Record<string, { vars: Record<string, any> }>;
    };
}

interface SortableHeaderProps {
    title: string;
    sortKey: string;
    currentSort: string;
    currentSortDirection: 'asc' | 'desc';
    onSort: (key: string) => void;
}

const SortableHeader: FC<SortableHeaderProps> = ({title, sortKey, currentSort, currentSortDirection, onSort}) => {
    const [isHovered, setIsHovered] = useState(false);

    const showArrow = currentSort === sortKey || isHovered;

    return (
        <Col xs={2} className="text-center">
            <h6
                style={{
                    cursor: 'pointer',
                    position: 'relative',
                }}
                onClick={() => onSort(sortKey)}
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
            >
                {title}
                {showArrow && (
                    <span style={{top: '1px', position: "absolute", marginLeft: '4px', opacity: currentSort === sortKey ? 1 : 0.5}}>
                        {currentSort === sortKey && currentSortDirection === 'asc'
                            ? <i className="bi bi-chevron-up"></i>
                            : <i className="bi bi-chevron-down"></i>
                        }
                    </span>
                )}
            </h6>
        </Col>
    );
};

const Sites: FC = () => {
    const { data: sites, error, isLoading, isError } = useGetSitesQuery();
    const [searchParams, setSearchParams] = useState("");
    const [sitesView, setSitesView] = useState("list");
    const [filteredSites, setFilteredSites] = useState<Site[]>([]);
    const [sortKey, setSortKey] = useState<string>("");
    const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('asc');
    const [appliedFilters, setAppliedFilters] = useState<AppliedFilter[]>([]);


    const gbucketSyncEnabled = useEnvConfig('GBUCKET_SYNC') === "true";

    const normalizeString = useCallback((str: string): string => {
        return str.toLowerCase().replace(/[^a-z0-9]/g, '');
    }, []);

    const handleSearch = (searchValue: string) => {
        setSearchParams(searchValue);
    };

    const setViewAsList = () => setSitesView("list");
    const setViewAsCards = () => setSitesView("card");

    const handleSort = (key: string) => {
        if (sortKey === key) {
            setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
        } else {
            setSortKey(key);
            setSortDirection('desc');
        }
    };

    useEffect(() => {
        if (sites && sites.results) {
            let filtered: Site[] = sites.results;



            // Apply filters if any exist
            if (appliedFilters.length > 0) {
                filtered = filtered.filter(site => {
                    return appliedFilters.every(filter => {
                        const [moduleGroup, moduleName, varName] = filter.id.split('-');
                        const valueToCheck = site.loaderConfig?.page?.[moduleName]?.vars?.[varName];

                        if (filter.type === 'Boolean') {
                            return valueToCheck === filter.value; // Boolean match
                        }
                        return valueToCheck == filter.value; // Loose equality for strings/numbers
                    });
                });
            }

            // Apply search filtering
            const normalizedSearchParams = normalizeString(searchParams);
            filtered = filtered.filter(({ id, name, publisher, displayName }) =>
                normalizeString(Object.values({ id, name, displayName, publisher }).join(""))
                    .includes(normalizedSearchParams)
            );

            // Apply sorting logic
            if (sortKey) {
                filtered.sort((a, b) => {
                    let aValue: number = 0;
                    let bValue: number = 0;

                    switch (sortKey) {
                        case 'presets':
                            aValue = a.presets?.length || 0;
                            bValue = b.presets?.length || 0;
                            break;
                        case 'modules':
                            aValue = Object.keys(a.moduleConfig || {}).length;
                            bValue = Object.keys(b.moduleConfig || {}).length;
                            break;
                        case 'errorRate':
                            aValue = a.stats?.logs.errorRate || 0;
                            bValue = b.stats?.logs.errorRate || 0;
                            break;
                        case 'fillRate':
                            aValue = a.stats?.logs.fillRate || 0;
                            bValue = b.stats?.logs.fillRate || 0;
                            break;
                        default:
                            return 0;
                    }

                    if (aValue < bValue) return sortDirection === 'asc' ? -1 : 1;
                    if (aValue > bValue) return sortDirection === 'asc' ? 1 : -1;
                    return 0;
                });
            }

            setFilteredSites(filtered); // Update the state with filtered results
        }
    }, [sites, appliedFilters, searchParams, sortKey, sortDirection, normalizeString]);





    if (isLoading) {
        return <Loader />;
    }

    if (isError || !sites) {
        return (
            <div>
                "there has been the following error: "
                {error ? error.toString() : null}
            </div>
        );
    }

    return (
        <>
            <Container fluid className={"mb-5"}>
                <Row>
                    <Col className="mt-4 mb-4">
                        <Stack direction={"horizontal"} gap={1}>
                            <h3>Sites</h3>
                            <Badge>{filteredSites && filteredSites.length} </Badge>
                        </Stack>
                    </Col>
                    <Col className="text-end mt-4 mb-4 d-flex justify-content-end">
                        <Stack direction="horizontal" gap={2}>
                            <AddSite showModal={false} />
                            {gbucketSyncEnabled && <GbucketSync />}
                        </Stack>
                    </Col>
                </Row>
                <div className="mt-2">
                    <Row className="mt-1">
                        <Col xl={4} lg={4} md={6}>
                            <div className="mt-2 mb-4">
                                <SearchBar
                                    placeholder="Search for site"
                                    onSearch={handleSearch}
                                    initialValue={searchParams}
                                />
                            </div>
                            <br/>
                        </Col>
                        <Col lg={3} md={6} className="d-flex ms-auto justify-content-end align-items-center">
                            <span className={"m-2"}>View as</span>
                            <SitesNavigation setViewAsList={setViewAsList} setViewAsCards={setViewAsCards} />
                        </Col>
                    </Row>
                </div>
                <div className="mt-2 mb-2 w-100">
                    <FilterInput onApplyFilters={setAppliedFilters} />
                </div>
                <ListViewWrapper>
                    {sitesView === "list" && (
                        <ListViewHeader className="d-flex justify-content-between align-items-center sticky-header">
                            <Col md={6} lg={6} sm={6} xs={6}><h6>Site Name</h6></Col>
                            <>
                                <Col md={5} lg={5} sm={5} xs={4}>
                                    <Row>
                                        <SortableHeader title="Presets" sortKey="presets" currentSort={sortKey} currentSortDirection={sortDirection} onSort={handleSort} />
                                        <SortableHeader title="Modules" sortKey="modules" currentSort={sortKey} currentSortDirection={sortDirection} onSort={handleSort} />
                                        <SortableHeader title="Error Rate" sortKey="errorRate" currentSort={sortKey} currentSortDirection={sortDirection} onSort={handleSort} />
                                        <SortableHeader title="Fill Rate" sortKey="fillRate" currentSort={sortKey} currentSortDirection={sortDirection} onSort={handleSort} />
                                        <Col xs={4} className="text-center"><h6>Activity</h6></Col>
                                    </Row>
                                </Col>
                                <Col md={1} sm={3} xs={2} className="d-flex justify-content-end"></Col>
                            </>
                        </ListViewHeader>
                    )}
                    {sitesView === "list" &&
                    filteredSites.map(({id, name, publisher, displayName, stats, moduleConfig, presets}, index) => (
                        <Col key={name + displayName} align={"center"}>
                            <SiteListElement
                                displayIndex={index}
                                id={name}
                                name={(displayName && capitalizeString(displayName)) || capitalizeString(name)}
                                publisher={capitalizeString(publisher)}
                                stats={stats}
                                moduleConfig={moduleConfig}
                                presets={presets}
                            />
                        </Col>
                    ))}
                </ListViewWrapper>
                {sitesView === "card" && (
                    <Row xs={2} md={4} xl={5} xxl={6}>
                        {filteredSites.map(({id, name, publisher, displayName}) => (
                            <Col key={name + displayName} align={"center"}>
                                <SiteCard
                                    id={name}
                                    name={(displayName && capitalizeString(displayName)) || capitalizeString(name)}
                                    publisher={capitalizeString(publisher)}
                                />
                            </Col>
                        ))}
                    </Row>
                )}
                {filteredSites.length === 0 && (
                    <Row>
                        <Col className="text-center mt-4">
                            <h5>No matches found.</h5>
                        </Col>
                    </Row>
                )}
            </Container>
        </>
    );
};

export default Sites;