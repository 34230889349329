import React, {FC} from 'react';
import {Line} from 'react-chartjs-2';
import {useState} from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    ChartOptions
} from 'chart.js';

import _ from 'lodash'

interface ActivityChartProps {
    stats: any,
    customOptions?: Partial<ChartOptions>;
}

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

function getRequestsforLastDays(requests: any, days: number) {
    const result: any = {}
    for (let i = days - 1; i >= 0; i--) {
        const date = new Date(); // Create a new date based on today's date
        date.setDate(date.getDate() - i); // Subtract i days from today's date
        const dateString = date.toISOString().split('T')[0];
        result[dateString] = requests[dateString] || 0;
    }
    return result;
}

const ActivityChart: FC<ActivityChartProps> = ({stats, customOptions = {}}) => {

    const requests = stats && getRequestsforLastDays(stats.requests, 7)
    const requestValues = Object.values(requests) as number[];
    const maxNumber = Math.max(...requestValues);

    // Round up to the nearest higher multiple of 1000
    const roundedUp = maxNumber > 0 ? Math.ceil(maxNumber / 1000) * 1000 : 5000;

    const defaultOptions: ChartOptions<'line'> = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                display: false,
            },
        },
        elements: {
            point: {
                pointStyle: "line",
            },
            line: {
                tension: 0.4, // Adjust this value to make the lines roundier
                capBezierPoints: true,
                borderWidth: 4
            }
        },
        scales: {
            y: {
                max: roundedUp,
                min: 0,
                display: true,
                title: {
                    display: false,
                },
            },
            x: {
                display: true,
                title: {
                    display: false,
                },
                ticks: {
                    display: false, // Hide the labels on the x-axis
                },
            }
        }
    };

    // Use Lodash's merge function to deeply merge the custom options into the default options
    const options = _.merge({}, defaultOptions, customOptions);

    const [data] = useState({
        labels: Object.keys(requests),
        datasets: [
            {
                data: Object.values(requests),
                borderColor: '#c1dcf8',
            }
        ]
    })


    return (
        <div style={{maxHeight: 60}}>
            {stats && data && <Line data={data} options={options}/>}
        </div>
    );
};
export default ActivityChart;

