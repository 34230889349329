import React, {useState, FC, useEffect} from 'react';
import {Modal, Button, Form, Spinner} from 'react-bootstrap';
import {convertPublisherName, publisherNamePattern} from "../../utils/utils";
import {useAddPublisherMutation, useGetPublishersQuery} from "../../redux/api/slothAPI";
import {Alert} from '../../styles/Alerts'

interface CreatePublisherModalProps {
    show: boolean;
    onHide: () => void;
    initialPublisherName?: string;
}

const CreatePublisherModal: FC<CreatePublisherModalProps> = ({show, onHide, initialPublisherName = ''}) => {
    const {data} = useGetPublishersQuery();
    const [createPublisher, {isLoading: isAdding}] = useAddPublisherMutation();
    const [addFeedback, setAddFeedback] = useState("");
    const [nameError, setNameError] = useState("");

    const [publisherName, setPublisherName] = useState(initialPublisherName);

    useEffect(() => {
        setPublisherName(initialPublisherName);
    }, [initialPublisherName]);


    const resetForm = () => {
        setPublisherName(initialPublisherName);
        setNameError("");
        setAddFeedback("");
    };

    const handleAddPublisher = (event: any) => {
        event.preventDefault();
        const publisherName = event.target.publisherName.value;

        const queryArgs = {
            name: publisherName,
        };

        const names = data?.results.map(publisher => convertPublisherName(publisher.name)) || [];

        if (names.includes(publisherName)) {
            setNameError("Publisher already exists");
            return;
        }

        if (!publisherNamePattern.test(publisherName)) {
            setNameError("Publisher name must not contain spaces and characters like  \" ' ` / ");
            return;
        }

        createPublisher(queryArgs)
            .unwrap()
            .then(() => {
                event.target.reset();
                setAddFeedback("Publisher successfully added");
            })
            .catch((error) => setAddFeedback(error.data.message));
    };

    return (
        <Modal show={show} onHide={() => { onHide(); resetForm(); }}>
            <Modal.Header closeButton>
                <Modal.Title>Add new publisher</Modal.Title>
            </Modal.Header>
            <Form autoComplete="off" onSubmit={handleAddPublisher}>
                <Modal.Body>
                    {isAdding ? (
                        <div className="text-center"><Spinner animation="border" variant="primary"/></div>
                    ) : addFeedback ? (
                        <Alert role="success">{addFeedback}</Alert>
                    ) : (
                        <>
                            <Form.Group className="mb-3">
                                <Form.Label>Publisher name</Form.Label>
                                <Form.Control
                                    value={publisherName}
                                    placeholder="Publisher name"
                                    name="publisherName"
                                    type="string"
                                    isInvalid={nameError !== ""}
                                    onChange={(e) => setPublisherName(e.target.value)}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {nameError}
                                </Form.Control.Feedback>
                            </Form.Group>
                        </>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => { onHide(); resetForm(); }}>Close</Button>
                    {!isAdding && !addFeedback && <Button variant="primary" type="submit">Add Publisher</Button>}
                </Modal.Footer>
            </Form>
        </Modal>
    );
};

export default CreatePublisherModal;
