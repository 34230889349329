import styled from "styled-components";
import { Button as BootstrapButton, ButtonProps as BootstrapButtonProps } from "react-bootstrap";
import { interFontStyle } from "../styles/Fonts";
import { device } from "../styles/Breakpoints";

// Define allowed variants for the Button
type CustomVariant = "primary" | "success" | "text-secondary" | "outline-secondary" | "remove" | "link" | "danger" | "secondary";

// Extend BootstrapButtonProps to include our custom variant type
interface CustomButtonProps extends BootstrapButtonProps {
  variant?: CustomVariant;
}

export const Button = styled(BootstrapButton)<CustomButtonProps>`
  i {
    margin-right: 5px;
  }

  @media ${device.sm} {
    span {
      display: none;
    }
  }

  ${interFontStyle}
  font-size: clamp(11px, 1vw, 14px);
  padding: 12px clamp(10px, 2vw, 24px);
  border-radius: 8px;
  box-shadow: 0px 0.5px 0.75px 0px #0b4a6fb2;
  border: none;

  ${({ variant }) => {
    switch (variant) {
      case "primary":
        return `
          background: #1570ef;
          &:hover {
            background: #6495ed;
          }
        `;
      case "success":
        return `
          background: #65a30d;
          &:hover {
            background: #9acd32;
          }
        `;
      case "text-secondary":
        return `
          background: none !important;
          color: #3985f1;
          &:hover {
            background: #9acd32;
          }
        `;
      case "outline-secondary":
        return `
          box-shadow: 0px 1px 2px 0px #1018280d;
          color: #3985f1;
          border: 1px solid #d0d5dd;
          background: #ffffff;
          &:hover {
            background: #e0f2fe;
            border-color: #d0d5dd;
            color: #3985f1;
          }
        `;
      case "remove":
        return `
          background: none;
          box-shadow: none;
          color: red;
          padding: 0;
        `;
      case "link":
        return `
          background: none;
          box-shadow: none;
          border: none;
          padding: 0;
        `;
      default:
        return "";
    }
  }}
`;
