import React, { FC, useState } from 'react';
import { Modal, Form, Spinner } from "react-bootstrap";
import { Button } from 'react-bootstrap';
import { Alert } from "../../styles/Alerts";
import { useAddNewSiteMutation } from "../../redux/api/slothAPI";
import { IAddSite } from "../../types";
import { siteNamePattern } from "../../utils/utils";
import SearchableDropdown from "../publishers/SearchableDropdown";
import PermissionButton from "../ButtonWithPermission";

const AddSite: FC<IAddSite> = ({ showModal }) => {
    const [createSite, { isLoading }] = useAddNewSiteMutation();
    const [show, setShow] = useState(showModal);
    const [showForm, setShowForm] = useState(true);
    const [siteNameError, setSiteNameError] = useState("");
    const [publisherNameError, setPublisherNameError] = useState("");
    const [addFeedback, setAddFeedback] = useState("");
    const [errorFeedback, setErrorFeedback] = useState("");
    const handleClose = () => {
        setShow(false);
        setShowForm(true);
        setAddFeedback("");
        setSiteNameError('');
        setPublisherNameError('');
    };

    const handleAddSite = (event: any) => {
        event.preventDefault();

        const siteNameInput = event.target.siteName.value;
        const url = event.target.url.value;
        const displayName = event.target.displayName.value;
        const publisherNameInput = event.target.publisherName.value;

        if (!siteNamePattern.test(siteNameInput)) {
            setSiteNameError("Site name must not contain spaces and special characters except - _ . | ");
            return;
        }

        if (!siteNamePattern.test(publisherNameInput)) {
            setPublisherNameError("Publisher name must not contain spaces and special characters except - _ . | ");
            return;
        }

        setShowForm(false);

        const queryArgs = {
            siteName: siteNameInput,
            url: url,
            publisherName: publisherNameInput,
            displayName: displayName,
        };

        createSite(queryArgs)
            .unwrap()
            .then((originalPromiseResult) => {
                setAddFeedback("Site added successfully");
                event.target.reset();
            })
            .catch((error) => setErrorFeedback(error.data.message));

        setSiteNameError('');
        setPublisherNameError('');
    };

    return (
        <>
            <PermissionButton variant="success" onClick={() => setShow(true)}>
                <i className="bi bi-plus"></i> Add New Site
            </PermissionButton>
            <Modal size="lg" show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Add new Site</Modal.Title>
                </Modal.Header>
                {isLoading && (
                    <div className="text-center pt-2 pb-2">
                        <Spinner className="pt-2 pb-2" animation="border" variant="primary" />
                    </div>
                )}
                {
                    (errorFeedback !== "" && <Alert role="error">{errorFeedback}</Alert>) ||
                    (addFeedback !== "" && <Alert role="success">{addFeedback}</Alert>)}
                {showForm && !isLoading && addFeedback === "" && (
                    <Form autoComplete="off" onSubmit={handleAddSite}>
                        <Modal.Body>
                            <Form.Group className="mb-3" controlId="siteName">
                                <Form.Label>Site name</Form.Label>
                                <Form.Control
                                    placeholder="site name"
                                    name="siteName"
                                    type="text"
                                    isInvalid={siteNameError !== ''}
                                    autoFocus
                                    onChange={() => setSiteNameError('')}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {siteNameError}
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label>URL</Form.Label>
                                <Form.Control
                                    placeholder="url"
                                    name="url"
                                    type="text"
                                />
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label>Display Name</Form.Label>
                                <Form.Control
                                    placeholder="display name"
                                    name="displayName"
                                    type="text"
                                />
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label>Publisher name</Form.Label>
                                <SearchableDropdown
                                    placeholder="Publisher name"
                                    name="publisherName"
                                    isInvalid={publisherNameError !== ''}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {publisherNameError}
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Modal.Body>
                        <Modal.Footer>

                            <Button variant="text-secondary" onClick={handleClose}>Close</Button>
                            <PermissionButton variant="primary" type="submit">
                                <i className="bi bi-plus"></i> Create Site
                            </PermissionButton>
                        </Modal.Footer>
                    </Form>
                )}
            </Modal>
        </>
    );
};

export default AddSite;
