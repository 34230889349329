import React, {FC} from 'react';
import {Bar} from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    TooltipItem,
    ChartOptions
} from 'chart.js';
import _ from 'lodash';


interface ReferrerChartProps {
    stats: {
        referrer: {
            [key: string]: number
        }
    },
    customOptions?: Partial<ChartOptions>;
}

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

const ReferrerChart: FC<ReferrerChartProps> = ({stats, customOptions = {}}) => {
    const referrers = stats.referrer;

    const defaultOptions: ChartOptions<'bar'> = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                display: false,
            },
            title: {
                display: false,
            },
            tooltip: {
                callbacks: {
                    label: function (context: TooltipItem<'bar'>) {
                        let label = context.dataset.label || '';
                        if (label) {
                            label += ': ';
                        }
                        if (context.parsed.y !== null) {
                            label += context.parsed.y;
                        }
                        return label;
                    }
                }
            }
        },
        scales: {
            x: {
                display: true,
                title: {
                    display: false,
                },
            },
            y: {
                display: true,
                title: {
                    display: false,
                },
                beginAtZero: true,
            }
        }
    };

    const data = {
        labels: Object.keys(referrers),
        datasets: [
            {
                label: 'Requests',
                data: Object.values(referrers),
                backgroundColor: 'rgba(54, 162, 235, 0.5)',
                borderColor: 'rgba(54, 162, 235, 1)',
                borderWidth: 1,
            }
        ]
    };

    // Use Lodash's merge function to deeply merge the custom options into the default options
    const options = _.merge({}, defaultOptions, customOptions);

    return (
        <div style={{maxHeight: '400px'}}>
            {stats && <Bar data={data} options={options}/>}
        </div>
    );
};

export default ReferrerChart;
