import React, {FC, useState} from 'react';
import {Accordion, Form } from "react-bootstrap";
import HistoryChange from "./HistoryChange"

interface HistoryAccordionProps {
    message: string
    commitId: string
    previousCommitId: string
    date?: string
    commitMessage?: string
    service : string
}

const HistoryAccordion: FC<HistoryAccordionProps> = ({message, commitId, previousCommitId, commitMessage , service}) => {

    const [isAccordionOpen, setIsAccordionOpen] = useState(false);

    const handleAccordionToggle = () => {
        setIsAccordionOpen(!isAccordionOpen);
    }

    return (
        <>
            <Accordion>
                <Accordion.Item eventKey={message}>
                    <Accordion.Header onClick={handleAccordionToggle}>
                        {message}
                    </Accordion.Header>
                    <Accordion.Body>
                        {isAccordionOpen && <HistoryChange service={service} commitId={commitId} previousCommitId={previousCommitId}/>}
                        {commitMessage &&
                        <Form.Group>
                            <Form.Label>Comment</Form.Label>
                            <Form.Control
                                type="text"
                                defaultValue={commitMessage}
                                readOnly
                            />
                        </Form.Group>
                        }
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
        </>
    );
};

export default HistoryAccordion;