import React, {FC} from 'react';
import Table from "react-bootstrap/Table";
import {ModuleDifference, SettingDifference} from "../../types";

interface ConfigChangesProps {
    configChanges?: ModuleDifference[]
    isConfigChange?: boolean
    presetsChanges?: any
    settingsChanges?: SettingDifference[]
    inventoryChanges?: ModuleDifference[] | any
}

const ConfigChanges: FC<ConfigChangesProps> = ({configChanges,
                                                   isConfigChange,
                                                   presetsChanges,
                                                   settingsChanges,
                                                   inventoryChanges}) => {

    //TODO: refactor component
    const systemChangeTypes = ["newModule", "deletedModule", "newVariable", "deleteVariable"];

    //add only changes who have type description
    const descriptiionChanges = configChanges?.filter((change) => {
        return (change.type && change.type === "description");
    } );

    //filter out system changes and description changes
    configChanges = configChanges?.filter((change) => {
        return (change.type && !systemChangeTypes.includes(change.type) && change.type !== "description");
    });
    return (
        <>
            {(configChanges && configChanges.length > 0 &&
                <Table striped bordered>
                    <thead>
                    <tr>
                        <th>Module</th>
                        <th>Variable</th>
                        <th>old Value</th>
                        <th>new Value</th>
                    </tr>
                    </thead>
                    <tbody>
                    {configChanges.map(({
                                            moduleName,
                                            variable,
                                            newValue,
                                            oldValue,
                                            type
                                        }, index) => {
                        return ((type && !systemChangeTypes.includes(type) && (
                            <tr key={moduleName + variable + index}>
                                <td>{moduleName}</td>
                                <td>{variable}</td>
                                <td>{(oldValue === "" ? "" : (oldValue || oldValue === 0 || oldValue === false) ? oldValue.toString() : "undefined")}</td>
                                <td>{(newValue === "" ? "" : (newValue || newValue === 0 || newValue === false) ? newValue.toString() : "undefined")}</td>
                            </tr>
                        )));
                    })}
                    </tbody>
                </Table>)
            }
            {
                presetsChanges && presetsChanges.length > 0 &&
                <>
                    <h5> Applied Presets </h5>
                    <Table striped bordered>
                        <thead>
                        <tr>
                            <th>old presets</th>
                            <th>new presets</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>

                            <td>{JSON.stringify(presetsChanges[0].oldValue)}</td>
                            <td> {JSON.stringify(presetsChanges[0].newValue)} </td>
                        </tr>

                        </tbody>
                    </Table>
                </>
            }
            {
                settingsChanges && settingsChanges.length > 0 &&
                <>
                    <h5> Applied Settings </h5>
                    <Table striped bordered responsive>
                        <thead>
                        <tr>
                            <th>Setting</th>
                            <th>old Value</th>
                            <th>new Value</th>
                        </tr>
                        </thead>
                        <tbody>
                        {settingsChanges.map(({
                                                  setting,
                                                  newValue,
                                                  oldValue
                                              }, index) => {
                                return (
                                    <tr key={setting + index}>
                                        <td>{setting}</td>
                                        <td>{JSON.stringify(oldValue)}</td>
                                        <td>{JSON.stringify(newValue)}</td>
                                    </tr>
                                );
                            }
                        )}

                        </tbody>
                    </Table>
                </>
            }
            {
                descriptiionChanges && descriptiionChanges.length > 0 &&
                <>
                    <h5> Description Changes </h5>
                    <Table striped bordered>
                        <thead>
                        <tr>
                            <th>Slot</th>
                            <th>old description</th>
                            <th>new description</th>

                        </tr>
                        </thead>
                        <tbody>

                        {descriptiionChanges.map(({
                                                      slot,
                                                      oldDescription,
                                                      description
                                                  }, index) => {
                            return (
                                <tr key={"description" + index}>
                                    <td>{slot}</td>
                                    <td>{oldDescription}</td>
                                    <td>{description}</td>
                                </tr>
                            );
                        })}

                        </tbody>
                    </Table>
                </>
            }
            {
                (!configChanges || configChanges.length===0 ) &&
                (!descriptiionChanges || descriptiionChanges.length === 0) &&
                (!presetsChanges || presetsChanges.length === 0) &&
                (!settingsChanges || settingsChanges.length === 0) &&
                (!inventoryChanges || inventoryChanges.length === 0) &&
                <p>
                    No changes to display
                </p>
            }
        </>
    );
};
export default ConfigChanges;