import React, { useState } from 'react';
import { Modal, Form, Spinner } from 'react-bootstrap';
import { Button } from "react-bootstrap";
import { useGbucketExtractorMutation } from "../redux/api/slothAPI";
import { useNavigate, useLocation } from "react-router-dom";
import PermissionButton from "./ButtonWithPermission";

// Define an interface for the component props
interface ImportURLModalProps {
    siteId?: string;
    presetId?: string;
    page: string;
    gbucketUrls?: string[];
    updateGbucketUrls?: (newUrls: string[]) => void;
}

const ImportURLModal: React.FC<ImportURLModalProps> = ({ siteId, presetId, page, gbucketUrls, updateGbucketUrls }) => {
    const [showModal, setShowModal] = useState(false);
    const [showProgressModal, setShowProgressModal] = useState(false);
    const [urls, setUrls] = useState<string[]>(['']);
    const [extractGbucket, { data, isLoading, isError, isSuccess, error }] = useGbucketExtractorMutation();
    const [statusMessage, setStatusMessage] = useState("");
    const navigate = useNavigate();
    const { pathname } = useLocation();

    const handleShowModal = () => setShowModal(true);
    const handleCloseModal = () => {
        setShowModal(false);
        setUrls(['']);
    };

    const handleGoToConfigurator = () => {
        const newPath = pathname.replace('/settings', '/configurator');
        navigate(newPath);
    };

    const addUrlField = () => setUrls([...urls, '']);

    const handleUrlChange = (index: number, value: string) => {
        const newUrls = [...urls];
        newUrls[index] = value;
        setUrls(newUrls);
    };

    const removeUrlField = (index: number) => {
        const newUrls = urls.filter((_, i) => i !== index);
        setUrls(newUrls);
    };

    const handleSaveImport = () => {
        extractGbucket({ urls: urls, id: siteId, presetId: presetId }).then((response: any) => {
            setStatusMessage(response?.error?.data?.message || "Config imported successfully");
            const extractedUrls = response.data.results[0].gbucketUrls || [];
            const uniqueNewUrls = Array.from(new Set([...(gbucketUrls || []), ...extractedUrls]));
            if (updateGbucketUrls) {
                updateGbucketUrls(uniqueNewUrls);
            }
        }).catch((error) => {
            console.log(error);
        });
        handleCloseModal();
        setShowProgressModal(true);
    };


    return (
        <>
            <PermissionButton variant="outline-secondary" onClick={handleShowModal}>
                <i className="bi bi-box-arrow-in-right"></i> Import gbucket
            </PermissionButton>

            <Modal show={showModal} onHide={handleCloseModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Import URLs</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h5 className="mb-5">Import Config From gbucket?</h5>
                    {urls.map((url, index) => (
                        <Form.Group key={index} className="mb-3">
                            <div className="d-flex justify-content-between align-items-center">
                                <Form.Label className="flex-grow-1">
                                    Config URL {index + 1}
                                </Form.Label>
                                {urls.length > 1 && (
                                    <Button variant="remove" onClick={() => removeUrlField(index)} className="ms-2">
                                        <i className="bi bi-x-square-fill"></i>
                                    </Button>
                                )}
                            </div>
                            <Form.Control
                                type="text"
                                placeholder="Enter URL"
                                value={url || ""}
                                onChange={(e) => handleUrlChange(index, e.target.value)}
                                className="flex-grow-1"
                            />
                        </Form.Group>
                    ))}
                    <Button variant="outline-secondary" onClick={addUrlField} className="w-100">
                        <i className="bi bi-plus"></i> Add url
                    </Button>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseModal}>Cancel</Button>
                    <Button variant="success" onClick={handleSaveImport}>Import config</Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showProgressModal} onHide={() => setShowProgressModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Importing Config</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {isLoading && <Spinner animation="border" variant="primary" />}
                    {isError && <p>Error importing config: {statusMessage}</p>}
                    {isSuccess && <>
                      <p>{statusMessage}</p>
                      <Button onClick={handleGoToConfigurator} variant="success">
                        Go to Configurator
                      </Button>
                    </>}
                </Modal.Body>
            </Modal>
        </>
    );
};

export default ImportURLModal;
