import React, {FC, useState} from 'react';
import {Container, Col, Row} from "react-bootstrap"
import { useLocation, useParams} from "react-router-dom";
import {convertConfigName, copyTextToClipboard} from "../../utils/utils";


import {Button} from "react-bootstrap";
import TagInTag from "./TagInTag";


const Tags: FC = () => {

    const [isCopied, setIsCopied] = useState(false);
    const location = useLocation();
    const isTagsPath = location.pathname.endsWith('/tags/') || location.pathname.endsWith('/tags');
    const routerParams = useParams();

    const getAllTagsToCopy = () => {
        let tags: string = ""
        const textAreas = document.getElementsByTagName('textarea');
        for (let i = 0; i <= textAreas.length - 1; i++) {
            i === 0 ? tags = tags + textAreas[i].value : tags = tags + "\n\n" + textAreas[i].value
        }
        return tags
    }

    const handleCopyClick = () => {
        copyTextToClipboard(getAllTagsToCopy())
            .then(() => {
                // If successful, update the isCopied state value
                setIsCopied(true);
                setTimeout(() => {
                    setIsCopied(false);
                }, 1500);
            })
            .catch((err) => {
                console.log(err);
            });
    }

    const downloadTagsAsTxt = () => {
        const element = document.createElement("a");
        const file = new Blob([getAllTagsToCopy()], {type: 'text/plain'});
        element.href = URL.createObjectURL(file);
        element.download = convertConfigName(routerParams.siteId) + ".txt";
        document.body.appendChild(element); // Required for this to work in FireFox
        element.click();
    }

    return (
        <Container fluid className="mb-5">
            <Row>
                <Col sm={2} md={2} lg={2} xs={2} className={"mb-5"}>
                    <Button className="ms-2"  href="https://docs.google.com/document/d/11oRpbZFRjr-niA-RgX-_-vj45xVgp26Lpqv4GCbH4GI/edit#heading=h.hgfure99q7g2" target="_blank">Implementation Guide</Button>
                </Col>
                {isTagsPath && (
                    <Col sm={10} md={10} lg={10} xs={10} className={"d-flex justify-content-end"}>
                        <div className="text-end">
                            <Button variant="outline-secondary" onClick={downloadTagsAsTxt}><i className="bi bi-download"></i>Download.txt</Button>
                            <Button className="ms-2" variant="outline-secondary" onClick={handleCopyClick}>{isCopied ? 'Copied!' : 'Copy All'}</Button>
                        </div>
                    </Col>
                )}
            </Row>

            <Row>
                <h2>Tags</h2>
            </Row>
            <TagInTag />
        </Container>
    );
};


export default Tags;




