import React, {FC, useState} from "react";
import {Link, Outlet, useParams} from "react-router-dom";
import HistoryModal from "../history/HistoryModal";
import {Nav, Container, Row, Col} from 'react-bootstrap';
import {Button} from "../../styles/Buttons";
import {convertConfigName} from "../../utils/utils";

import TabNavigation from "./TabNav";

const Site: FC = () => {

    // Getting site ID from route and RTK query hooks
    const routerParams = useParams();
    // show and close pop up for confirmation and changes
    const [showHistory, setShowHistory] = useState(false);
    const changeHistoryVisibility = () => setShowHistory(!showHistory);
    const siteId = useParams().siteId;

    return (
        <>
            <HistoryModal showHistory={showHistory} setShowHistory={changeHistoryVisibility} configId={routerParams.siteId} service={"site"}/>
            <Container className="mt-3" fluid>
                <Row>
                    <Col>
                        <Nav>
                            <Link to={"/sites"}><Button variant="outline-secondary"><i className="bi bi-arrow-left"></i>Back</Button></Link>
                        </Nav>
                    </Col>
                    <Col className="d-flex justify-content-center">
                        <h3>{convertConfigName(siteId)}</h3>
                    </Col>
                    <Col className="d-flex justify-content-end">
                        <Button variant="outline-secondary" onClick={changeHistoryVisibility} className="m-1"><i className="bi bi-clock-history"></i>View Log History</Button>
                    </Col>
                </Row>
                <TabNavigation/>
            </Container>
            <Outlet/>
        </>
    )
};

export default Site
