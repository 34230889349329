import React, {FC, useState} from 'react';
import {Row, Col} from 'react-bootstrap';

import {useConfiguratorContext} from "../../hooks/configuratorContext";
import ConfigNavigation from "./ConfigNavigation";
import ContextModules from "./context/ContextModules";

interface IModuleConfig {
    location: string;
}

const ModuleConfig: FC<IModuleConfig> = ({location}) => {
    const [selectedModuleGroup, setSelectedModuleGroup] = useState<string[]>([]);

    const {moduleGroups, builderConfig} = useConfiguratorContext()

    const handleSelectedModuleGroup = (moduleGroup: string) => {
        if (selectedModuleGroup.includes(moduleGroup)) {
            setSelectedModuleGroup(selectedModuleGroup.filter((group) => group !== moduleGroup));
        } else {
            setSelectedModuleGroup([...selectedModuleGroup, moduleGroup]);
        }
    }


    return (
        <>
            <Row>
                <Col lg={3} xs={12}>
                    {moduleGroups && <ConfigNavigation elements={moduleGroups} selectedElements={selectedModuleGroup}
                                                       handleSelect={handleSelectedModuleGroup}/>}
                </Col>
                <Col lg={9} xs={12}>
                    {moduleGroups && builderConfig && moduleGroups.map((moduleGroup, index) => {

                        return (
                            <div key={moduleGroup} style=
                                {selectedModuleGroup.length === 0 || selectedModuleGroup.includes(moduleGroup) ? {display: "block"} : {display: "none"}}
                            >
                                <h4>{moduleGroup}</h4>
                                <div className={"sloth-box"}>
                                   <ContextModules groupId={moduleGroup} location={location}/>
                                </div>
                            </div>
                        )
                    })}
                </Col>
            </Row>
        </>
    );
};

export default ModuleConfig;
