import './wdyr';
import React from 'react';
import ReactDOM from 'react-dom/client';
import 'index.css';
import './goldbach.css';
import App from 'App';
import reportWebVitals from 'reportWebVitals';

import {AuthProvider} from "./config/AuthContext";


// ? Import Provider from react-redux and store from ./redux/store
import {Provider} from 'react-redux';
import {store} from './redux/store';

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

root.render(

        <Provider store={store}>
            <AuthProvider>
                <App/>
            </AuthProvider>
        </Provider>

);

reportWebVitals();

