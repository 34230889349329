import React, { FC, useEffect, useState, useCallback } from "react";
import { Row, Col, Container, Form, Modal, Spinner, Stack , Button} from "react-bootstrap"
import SiteListElement from "../sites/SiteListElement";
import { convertPresetName, presetNamePattern } from "../../utils/utils";
import { useAddPresetMutation, useDeletePresetMutation, useGetPresetsQuery } from "../../redux/api/slothAPI";
import { Badge } from "../../styles/CommonComponents";

import { ListViewWrapper } from "../../styles/Sites/SiteView";
import { Alert } from "../../styles/Alerts";
import SearchBar from "../SearchBar";
import PermissionButton from "../ButtonWithPermission";
import Loader from "../Loader";

interface ISite {
    id: string;
    name: string;
    publisher: string;
    displayName?: string;
}

const Presets: FC = () => {
    // Redux hooks
    const { data: presets, isLoading } = useGetPresetsQuery()
    const [createPreset, { isLoading: isAdding }] = useAddPresetMutation()
    const [deletePreset, { isLoading: isDeleting }] = useDeletePresetMutation()

    const [deleteFeedback, setDeleteFeedback] = useState("");
    const [addFeedback, setAddFeedback] = useState("")
    const [errorFeedback, setErrorFeedback] = useState("")

    // Hide and show modals
    const [showAddModal, setShowAddModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);

    const handleCloseAddModal = () => {
        setAddFeedback("")
        setNameError("")
        setShowAddModal(false)
    }

    const handleShowAddModal = () => setShowAddModal(true);

    const handleCloseDeleteModal = () => {
        setShowDeleteModal(false)
        // Set messages after modal is closed
        setTimeout(() => {
            setPresetToDelete("")
            setDeleteFeedback("")
        }, 200)
    }

    const handleShowDeleteModal = (name: string) => {
        setPresetToDelete(name)
        setShowDeleteModal(true);
    }

    // Search and filter presets
    const [searchInput, setSearchInput] = useState('');
    const [filteredPresets, setFilteredPresets] = useState<ISite[]>([]);

    const normalizeString = useCallback((str: string): string => {
        return str.toLowerCase().replace(/[^a-z0-9]/g, '');
    }, []);

    const searchItems = (searchValue: string) => {
        setSearchInput(searchValue)
    }

    useEffect(() => {
        if (presets && presets.results) {
            const normalizedSearchInput = normalizeString(searchInput);
            const filtered: ISite[] = presets.results.filter(({ id, name }) =>
                normalizeString(Object.values({ id, name }).join('')).includes(normalizedSearchInput)
            );
            setFilteredPresets(filtered);
        }
    }, [presets, searchInput, normalizeString]);

    // Error handling
    const [nameError, setNameError] = useState("")

    // Delete preset
    const [presetToDelete, setPresetToDelete] = useState<string>("")
    const handleDelete = (id: string) => {
        const queryArgs = {
            id: id,
        }
        deletePreset(queryArgs)
            .unwrap()
            .then(() => {
                setDeleteFeedback("Preset successfully deleted");
                setPresetToDelete("")
                // Hard reload page
                window.location.reload();
            })
            .catch((error) => setDeleteFeedback(error.data.message));
    }

    const handleAddPreset = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const form = event.target as HTMLFormElement;
        const preset = (form.elements.namedItem('name') as HTMLInputElement).value;
        const description = (form.elements.namedItem('description') as HTMLInputElement).value;

        const queryArgs = {
            name: preset,
            description: description
        }

        if (!presetNamePattern.test(preset)) {
            setNameError("Preset name must not contain spaces and characters like  \" ' ` / ");
            return;
        }
        createPreset(queryArgs)
            .unwrap()
            .then(() => {
                form.reset();
                setAddFeedback("Preset successfully added");
            })
            .catch((error) => setErrorFeedback(error.data.message));
    }

    const DeleteButton = (name: string) => {
        return (
            <PermissionButton className={"m-1"} onClick={() => handleShowDeleteModal(name)} variant="danger">
                <i className="bi bi-trash"> </i>
            </PermissionButton>
        )
    }

    if (isLoading) {
        return <Loader />;
    }

    return (
        <>
            <Container fluid className={"mb-5"}>
                <Row>
                    <Col className="mt-4 mb-4">
                        <Stack direction={"horizontal"} gap={1}>
                            <h3>Presets </h3>
                            <Badge className={"mb-1"} bg="secondary">{filteredPresets && filteredPresets.length} </Badge>
                        </Stack>
                    </Col>
                    <Col className="text-end align-content-end mt-4 mb-4">
                        <PermissionButton variant="success" onClick={handleShowAddModal}>
                            <i className="bi bi-plus"></i>Add New Preset
                        </PermissionButton>
                    </Col>
                </Row>
                <div className="mt-2">
                    <Row className={" mt-1"}>
                        <Col lg={4} md={12} xs={12}>
                            <div className="mt-2 mb-4">
                                <SearchBar onSearch={searchItems} placeholder="Search for preset"/>
                            </div>
                        </Col>
                    </Row>
                </div>
                <ListViewWrapper>
                    {(filteredPresets.length > 0 && filteredPresets.map((preset, index) => {
                        return (
                            <SiteListElement
                                key={index + preset.name}
                                id={preset.name}
                                name={preset.name}
                                preset={true}
                                DeleteButton={DeleteButton(preset.name)}
                            />
                        )
                    })) ||
                    <div className="text-center mt-5">
                      <p>No presets found</p>
                    </div>
                    }
                </ListViewWrapper>
            </Container>

            {/* Pop up to add a preset */}
            <Modal size="lg" show={showAddModal} onHide={handleCloseAddModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Add new Preset</Modal.Title>
                </Modal.Header>

                <Form onSubmit={handleAddPreset}>
                    <Modal.Body>
                        {(isAdding &&
                          <div className="text-center"><Spinner animation="border" variant="primary"/></div>) ||
                        (addFeedback !== "" && <Alert role="success">{addFeedback}</Alert>) ||
                        (errorFeedback !== "" && <Alert role="error">{errorFeedback}</Alert>) ||
                        (<>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                <Form.Label>Preset name</Form.Label>
                                <Form.Control
                                    placeholder="preset name"
                                    name="name"
                                    type="string"
                                    isInvalid={nameError !== ""}
                                    autoFocus
                                />
                                <Form.Control.Feedback type="invalid">
                                    {nameError}
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput2">
                                <Form.Label>Preset description</Form.Label>
                                <Form.Control
                                    placeholder="preset description"
                                    name="description"
                                    type="string"
                                />
                            </Form.Group>
                        </>)}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleCloseAddModal}>
                            Close
                        </Button>
                        {!isAdding && addFeedback === "" && <Button variant="primary" type="submit">
                          Add Preset
                        </Button>}
                    </Modal.Footer>
                </Form>
            </Modal>

            {/* Pop Up to delete a preset */}
            <Modal size="lg" show={showDeleteModal} onHide={handleCloseDeleteModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Delete Preset</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {(isDeleting &&
                      <div className="text-center">
                        <Spinner animation="border" variant="primary"/>
                      </div>)
                    ||
                    (
                        deleteFeedback !== "" && <Alert role="success">{deleteFeedback}</Alert>
                    ) ||
                    <p>Are you sure you want to delete the following preset: {convertPresetName(presetToDelete)}?</p>
                    }
                </Modal.Body>
                <Form onSubmit={() => handleDelete(presetToDelete)}>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleCloseDeleteModal}>
                            Close
                        </Button>
                        {
                            !isDeleting && deleteFeedback === "" && <Button variant="danger" type="submit">
                              Delete Preset
                            </Button>
                        }
                    </Modal.Footer>
                </Form>
            </Modal>
        </>
    );
};

export default Presets;