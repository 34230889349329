import React, {FC} from 'react';
import {Link} from "react-router-dom";
import {Card} from '../../styles/Sites/SiteCard';
import {Button} from 'react-bootstrap'

import {showInitials, convertConfigName} from "../../utils/utils";
import {SiteCardProps} from "../../types";


const SiteCard: FC<SiteCardProps> = ({id, name, publisher, logo, value}) => {
    return (
        <>
            <Card className="text-center m-6 mt-6 mb-3 w-auto">
                <p data-letters={name && showInitials(name)}></p>
                <Card.Body>
                    <Card.Title>{convertConfigName(name)}</Card.Title>
                    <Link to={"/site/" + id + "/configurator"}>
                        <Button variant="primary"><i className="bi bi-pencil"></i><span>Edit Config</span></Button>
                    </Link>
                </Card.Body>
            </Card>
        </>
    );
};
export default SiteCard;