import React, {FC, useState} from 'react';
import {Modal, Form} from "react-bootstrap";
import {inventoryNamePattern}  from "../../utils/utils";
import {Button} from "../../styles/Buttons";
import PermissionButton from "../ButtonWithPermission";

interface IAddInventory {
    addSlot : (name : string) => void
    inventory? : string[]
}

const AddInventory: FC<IAddInventory> = ({ addSlot , inventory}) => {

    const [show, setShow] = useState<boolean>(false);
    const [slotName, setSlotName] = useState<string>("");
    const [nameError, setNameError] = useState<string>("");

    const handleClose = () => {
        setShow(false);
        setSlotName("");
        setNameError("");
    };

    const handleShow = () => setShow(true);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {


        const input = event.target.value;
        const regex = /^\S*$/; // Regex to match strings without spaces
        if (regex.test(input)) {
            setSlotName(input);
        }
    }

    const submitAddSlot = (event: any) => {
        event.preventDefault();
        event.stopPropagation();
        const newSlotName = slotName.trim(); // Remove leading and trailing whitespace

        if (!inventoryNamePattern.test(slotName)) {
            setNameError("Slot name must not contain spaces and special characters except  - _ and / ");
            return;
        }
        if (inventory?.includes(newSlotName)) {
            setNameError("Name already exists");
            return;
        }


        addSlot(newSlotName)
        handleClose()
    }

    return (
        <>
            <div className="d-grid gap-2 mt-2">
                <PermissionButton variant="success" onClick={handleShow}><i className="bi bi-plus"></i>Add Slot</PermissionButton>
            </div>
            <Modal size="sm" show={show} onHide={handleClose} >
                <Modal.Header closeButton>
                    <Modal.Title>Add Inventory</Modal.Title>
                </Modal.Header>
                <Form validated={false} onSubmit={submitAddSlot} >
                    <Modal.Body>
                        <Form.Group className="mb-5">
                            <Form.Control
                                placeholder="slot name"
                                name="siteName"
                                type="string"
                                autoFocus
                                value={slotName}
                                onChange={handleChange}
                                isInvalid = {nameError !== ''}
                                required
                            />
                            <Form.Control.Feedback type="invalid">{nameError}</Form.Control.Feedback>
                        </Form.Group>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>Close</Button>
                        <Button type="submit" variant="primary" >Add Inventory</Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        </>
    );
};

export default AddInventory;







