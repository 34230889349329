import styled from 'styled-components';


export const HomePageWrapper = styled.div`
  a{
    color: #667085
  }
  img{
    max-width: 100%;
  }
  .sloth-image {
    text-align: center;
    padding: 0 15px;
  }

  .container {
    border: none
  }

  .card {
    text-align: center;
  }

  .welcome-section {
    background: #010E1E;
  }

`;

