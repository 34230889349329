import styled from 'styled-components';

export const StatContainer = styled.div`
  
  p{
    text-align: center;
    font-size: 35px;
  }
  h4 {
    padding: 10px 20px;
    border-bottom: 1px solid #ECEEF6;
    font-size: 22px;
  }

  > div {
    padding: 0px 24px;
  }

  background: #ffffff;
  border-radius: 8px;
  border: 1px solid #ECEEF6;
  padding: 0px 0px 24px 0px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);

  flex-grow: 1;
  display: flex;
  flex-direction: column;
  
`;

