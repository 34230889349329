import React, {FC, useState, useEffect, useRef} from 'react';
import Form from "react-bootstrap/Form";
import { IVariable } from "../../../types";
import { useFormContext } from "react-hook-form";
import { changeOriginInName } from "../../../utils/config-helper";
import { useConfiguratorContext } from "../../../hooks/configuratorContext";


const ContextInputVariable: FC<IVariable> = ({
                                                 name,
                                                 moduleName,
                                                 formIdentifier,
                                                 isVariableGroup,
                                                 header,
                                                 groupId,
                                                 location,
                                             }) => {
    const methods = useFormContext();
    const [isInValid, setIsInvalid] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [localName, setLocalName] = useState(formIdentifier);
    const { mergedConfig, addToLocalModuleConfig, addToLocalInventoryConfig } = useConfiguratorContext();

    const mergedConfigCopy =
        location &&
        groupId &&
        (["site", "preset"].includes(location)
            ? mergedConfig?.moduleConfig[groupId]
            : mergedConfig?.inventoryConfig[groupId]);
    const variableConfig =
        groupId &&
        (isVariableGroup && header
            ? mergedConfigCopy[moduleName]?.[header][name]
            : mergedConfigCopy[moduleName]?.[name]);
    const isUrl = variableConfig?.type.toLowerCase() === "url";
    const type = variableConfig?.type.toLowerCase() === "url" ? "string" : variableConfig?.type.toLowerCase();
    const min = variableConfig?.min;
    const max = variableConfig?.max;
    const step = variableConfig?.step;
    const readOnly = variableConfig?.readOnly;
    const [value, setValue] = useState(variableConfig?.value);

    const inputRef = useRef<HTMLInputElement | HTMLTextAreaElement>(null);

    // Update value when variableConfig changes
    useEffect(() => {
        setValue(variableConfig?.value);
        methods.register(localName);
        if (variableConfig?.value && typeof variableConfig?.value === "object") {
            methods.setValue(localName, JSON.stringify(variableConfig?.value));
        } else {
            methods.setValue(localName, variableConfig?.value);
        }

        // Adjust the height after setting the value
        if (inputRef.current && type !== "number") {
            inputRef.current.style.height = 'auto';
            inputRef.current.style.height = `${inputRef.current.scrollHeight}px`;
        }
    }, [variableConfig?.value, localName, methods ]);



    useEffect(() => {
        methods.unregister(localName);
        const newLocalName = changeOriginInName(formIdentifier, variableConfig?.origin);
        setLocalName(newLocalName);
    }, [variableConfig?.origin, formIdentifier]);



    const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        event.preventDefault();
        const inputValue = event.target.value;
        const newLocalName = location ? changeOriginInName(formIdentifier, location) : localName;
        methods.setValue(newLocalName, inputValue);

        // Update configs with newLocalName
        if (addToLocalModuleConfig && location && ["site", "preset"].includes(location)) {
            addToLocalModuleConfig(moduleName, name, inputValue, newLocalName);
        }
        if (
            location &&
            groupId &&
            ["inventory", "preset-inventory"].includes(location) &&
            addToLocalInventoryConfig
        ) {
            addToLocalInventoryConfig(moduleName, name, groupId, inputValue, newLocalName);
        }

        // Validation logic...

        setValue(inputValue);
    };

    const handleChangeNumber = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        let inputValue = event.target.value;
        inputValue = inputValue.replace(',', '.');
        const newLocalName = location ? changeOriginInName(formIdentifier, location) : localName;
        methods.setValue(newLocalName, inputValue);

        // Update configs with newLocalName
        if (addToLocalModuleConfig && location && ["site", "preset"].includes(location)) {
            addToLocalModuleConfig(moduleName, name, inputValue, newLocalName);
        }
        if (
            location &&
            groupId &&
            ["inventory", "preset-inventory"].includes(location) &&
            addToLocalInventoryConfig
        ) {
            addToLocalInventoryConfig(moduleName, name, groupId, inputValue, newLocalName);
        }

        // Validation logic...

        setValue(inputValue);
    };



    const inputStyle = {
        maxWidth: '500px',
        minHeight: '38px',
        overflow: 'hidden',
    };


    // @ts-ignore
    return (
        <div key={name + moduleName}>
            {type === "number" ? (
                <Form.Control
                    className="float-start me-1"
                    {...methods.register(localName, {
                        valueAsNumber: true,
                        min: min,
                        max: max,
                    })}
                    name={localName}
                    value={value ?? ''}
                    onChange={handleChangeNumber}
                    type="number"
                    placeholder={
                        variableConfig?.value === 0 || variableConfig?.value === undefined
                            ? variableConfig?.default
                            : ''
                    }
                    isInvalid={isInValid}
                    disabled={readOnly || false}
                    step={step || ".01"}
                    style={{ ...inputStyle, height: '38px' }} // Fixed height for number input
                    ref={inputRef as React.RefObject<HTMLInputElement>}
                />
            ) : (
                <Form.Control
                    className="float-start me-1"
                    {...methods.register(localName)}
                    name={localName}
                    onChange={handleChange}
                    type="text"
                    placeholder={
                        (type === "object" || type === "array"
                            ? JSON.stringify(variableConfig?.default)
                            : variableConfig?.default) || ''
                    }
                    value={
                        value
                            ? (type === "object" || type === "array") && typeof value === "object"
                                ? JSON.stringify(value)
                                : value
                            : ''
                    }
                    isInvalid={isInValid}
                    disabled={readOnly || false}
                    style={inputStyle}
                    as="textarea"
                    rows={1}
                    ref={inputRef as React.RefObject<HTMLTextAreaElement>}
                />
            )}
            <Form.Control.Feedback type="invalid" style={{ clear: 'both', float: "left" }}>
                {errorMessage}
            </Form.Control.Feedback>
        </div>
    );
};

export default ContextInputVariable;
