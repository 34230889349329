import React, {FC, useState} from 'react';
import {Button, Form, Row, Col} from "react-bootstrap";
import {convertConfigName, copyTextToClipboard} from "../../utils/utils";
import {useEnvConfig} from "../../redux/hooks";
interface ITagGenerator {
    siteName?: string
    inventory?: string
    rows: number
    environment: string
    tagOptions?: string
}

const TagGenerator: FC<ITagGenerator> = ({siteName, inventory, rows, environment , tagOptions}) => {

    console.log(tagOptions)

    const [isCopied, setIsCopied] = useState(false);

    const domain = useEnvConfig("TAG_URL");


    const tag = (inventory && `<div id="gb-${inventory}"></div>\n<script src="https://${domain}/${environment}/loader/${siteName && convertConfigName(siteName)}.loader.js"  data-slot="${inventory}" ${tagOptions || ""} crossorigin>\n</script>`)
        ||
        `<script src="https://${domain}/${environment}/loader/${siteName && convertConfigName(siteName)}.loader.js" data-tit ${tagOptions || ""} crossorigin></script>`



    const handleCopyClick = () => {
        copyTextToClipboard(tag)
            .then(() => {
                // If successful, update the isCopied state value
                setIsCopied(true);
                setTimeout(() => {
                    setIsCopied(false);
                }, 1500);
            })
            .catch((err) => {
                console.log(err);
            });
    }

    return (
        <>
            <Row>
                <Col md={12} lg={12}>
                    <Form.Text muted>geneated tag: </Form.Text>
                </Col>
            </Row>
            <Row>

                <Col md={10} lg={10} xl={11}>
                    <Form.Control type="text" readOnly value={tag} as="textarea" rows={rows}/>
                </Col>
                <Col md={2} lg={2} xl={1} className="d-flex align-items-center justify-content-center">
                    <Button className="w-100" onClick={handleCopyClick}><i className="bi bi-copy"></i>{isCopied ? 'Copied!' : 'Copy'} </Button>
                </Col>
            </Row>
        </>
    );
};

export default TagGenerator;




