import React, { FC, useEffect, useMemo, useRef, useState } from 'react';
import {
    useParams,
    useBlocker,
} from 'react-router-dom';
import { TabNav, TabNavItem } from '../../styles/TabNav';
import { Container, Row, Col, Form } from 'react-bootstrap';
import { useForm, FormProvider } from 'react-hook-form';
import ModuleConfig from './ModuleConfig';
import InventoryConfig from '../inventory/InventoryConfig';
import { useGetCustomConfig } from '../../hooks/slothCustomHooks';
import { ConfiguratorContext } from '../../hooks/configuratorContext';
import { ModuleDifference } from '../../types';
import {
    usePutPresetConfigMutation,
    usePutSiteConfigMutation,
} from '../../redux/api/slothAPI';
import Loader from '../Loader';
import ConfigModals from './ConfigModal';
import NavigationModal from './NavigationModal';
import PermissionButton from '../ButtonWithPermission';

import {
    addMetaToInventoryConfig,
    changeInventoryConfig,
    comparePartialModuleConfig,
    contextAddSlot,
    contextDeleteSlot,
    convertFormDataToPartialConfig,
    createInventoryConfig,
    getMergedConfig,
    isNotConfigured,
    saveChanges,
} from '../../utils/config-helper';

import { removeObjectsWithEmptyFirstKeyAndNullValue } from '../../utils/utils';

interface IConfigurator {
    location: string;
}

const Configurator: FC<IConfigurator> = ({ location }) => {

    const routerParams = useParams();
    // Derive inventory location
    const inventoryLocation = location === 'site' ? 'inventory' : 'preset-inventory';
    // Query arguments
    const queryArgs: any = {
        id: location === 'site' ? routerParams?.siteId : routerParams?.presetId,
    };

    // Fetch config data
    const {
        moduleGroups,
        config,
        slots,
        onlyPresetSlots,
        inactiveSlots,
        appliedPresetsConfig,
        isLoading,
        isAppliedPresetsLoading,
    } = useGetCustomConfig(queryArgs.id, location);

    const siteId = routerParams?.site;

    // React Hook Form
    const methods = useForm();

    // Tab state
    const [activeTab, setActiveTab] = useState('site');

    // Show and close pop up for save progress
    const [progressModalVisible, setProgressModalVisible] = useState(false);
    const changeProgressModalVisibility = () => setProgressModalVisible(!progressModalVisible);

    // Show and close pop up for confirmation and changes
    const [confirmationModalVisible, setConfirmationModalVisible] = useState(false);
    const changeConfirmationModalVisibility = () => setConfirmationModalVisible(!confirmationModalVisible);

    // Track if user made any changes
    const [allowNavigation, setAllowNavigation] = useState(true);

    // Navigation modal state
    const [navigationModalVisible, setNavigationModalVisible] = useState(false);

    // Because `unstable_useBlocker` doesn’t store the path for you,
    // you can keep `pendingPath` if you want to show or log which path is being blocked.
    const [pendingPath, setPendingPath] = useState<string | null>(null);

    // Configuration changes state
    const [configChanges, setConfigChanges] = useState<ModuleDifference[]>([]);
    const [inventoryChanges, setInventoryChanges] = useState<ModuleDifference[]>([]);
    const [statusMessage, setStatusMessage] = useState('');

    // Post config mutation
    const postQuery =
        location === 'site' ? usePutSiteConfigMutation : usePutPresetConfigMutation;
    const [postConfig, { isLoading: postConfigIsLoading }] = postQuery();

    // Local state for module & inventory config
    const [localModuleConfig, setLocalModuleConfig] = useState<any>();
    const [localInventoryConfig, setLocalInventoryConfig] = useState<any>();
    const [deletedPresetSlots, setDeletedPresetSlots] = useState<string[]>([]);
    const [mergedConfig, setMergedConfig] = useState<any>(undefined);

    // Final config to post (ref)
    const currentConfigToPost = useRef({
        moduleConfig: {},
        inventoryConfig: {},
        gbucketUrls: undefined,
    });

    // Merge config whenever dependencies update
    useMemo(() => {
        if (
            config?.builderConfig &&
            localModuleConfig &&
            localInventoryConfig &&
            appliedPresetsConfig &&
            location
        ) {
            setMergedConfig(
                getMergedConfig(
                    config?.builderConfig,
                    localModuleConfig,
                    localInventoryConfig,
                    appliedPresetsConfig,
                    location,
                    config?.gbucket
                )
            );
        }
    }, [config?.gbucket, localModuleConfig, localInventoryConfig, appliedPresetsConfig, location]);

    // Initialize local configs after data is loaded
    useEffect(() => {
        setLocalModuleConfig(config?.moduleConfig || {});
        setLocalInventoryConfig({
            inventoryConfig: config?.inventoryConfig || {},
            slots,
            onlyPresetSlots,
            inactiveSlots,
        });
    }, [config?.inventoryConfig, config?.moduleConfig, slots, onlyPresetSlots, inactiveSlots]);


    // Blocker
    const blocker : any = useBlocker(!allowNavigation); // If !allowNavigation is true, block navigation

    useEffect(() => {
        if (blocker.state === 'blocked') {
            // Navigation is being attempted
            // If you want to see the target location, it's in blocker.location
            const { pathname } = blocker.location || {};
            setPendingPath(pathname || null);

            // Show your unsaved-changes modal
            setNavigationModalVisible(true);
        }
    }, [blocker.state, blocker.location]);


    // Slot manipulations
    const deleteSlot = (inventory: string) => {
        contextDeleteSlot(
            inventory,
            localInventoryConfig,
            setLocalInventoryConfig,
            deletedPresetSlots,
            setDeletedPresetSlots
        );
    };

    const addSlot = (name: string) => {
        contextAddSlot(name, localInventoryConfig, setLocalInventoryConfig);
    };

    // Update local module config
    const addToLocalModuleConfig = (
        module: string,
        variable: string,
        value: any,
        formIdentifier: string
    ) => {
        if (allowNavigation) {
            setAllowNavigation(false);
        }

        const result = structuredClone(localModuleConfig);
        result[module] = {
            vars: {
                ...result[module]?.vars,
                [variable]: value,
            },
        };
        setLocalModuleConfig(result);
    };

    // Update local inventory config
    const addToLocalInventoryConfig = (
        module: string,
        variable: string,
        slot: string,
        value: any,
        formIdentifier: string
    ) => {
        if (allowNavigation) {
            setAllowNavigation(false);
        }

        const result = structuredClone(localInventoryConfig);
        if (!result.inventoryConfig[slot]) {
            result.inventoryConfig[slot] = {};
        }
        result.inventoryConfig[slot][module] = {
            ...result.inventoryConfig[slot][module],
            vars: {
                ...result.inventoryConfig[slot][module]?.vars,
                [variable]: value,
            },
        };
        setLocalInventoryConfig(result);
    };

    // Unset variable
    const unsetVariable = (
        module: string,
        variable: string,
        configLocation: string,
        inventory: string,
        formIdentifier: string
    ) => {
        methods.unregister(formIdentifier);

        let moduleValue =
            localModuleConfig && localModuleConfig[module]?.vars[variable];

        // If variable is in module config
        if (
            (moduleValue ||
                moduleValue === false ||
                moduleValue === 0 ||
                moduleValue === '' ||
                isNaN(moduleValue)) &&
            ['site', 'preset'].includes(configLocation) &&
            module
        ) {
            const result = structuredClone(localModuleConfig);
            delete result[module].vars[variable];
            if (Object.keys(result[module].vars).length === 0) {
                delete result[module];
            }
            setLocalModuleConfig(result);
        }

        // If variable is in inventory config
        let value =
            localInventoryConfig?.inventoryConfig?.[inventory]?.[module]?.vars[variable];
        if (
            (value ||
                value === false ||
                value === 0 ||
                value === '' ||
                isNaN(value)) &&
            ['inventory', 'preset-inventory'].includes(configLocation) &&
            inventory
        ) {
            const result = structuredClone(localInventoryConfig.inventoryConfig);
            delete result[inventory][module].vars[variable];
            if (Object.keys(result[inventory][module].vars).length === 0) {
                delete result[inventory][module];
            }
            setLocalInventoryConfig({
                ...localInventoryConfig,
                inventoryConfig: result,
            });
        }
    };

    // Save changes
    const handleSaveChanges = (message: string) => {
        saveChanges(
            message,
            {
                moduleConfig: currentConfigToPost.current.moduleConfig,
                inventoryConfig: currentConfigToPost.current.inventoryConfig,
                gbucketUrls: currentConfigToPost.current.gbucketUrls,
            },
            setStatusMessage,
            setConfigChanges,
            changeConfirmationModalVisibility,
            changeProgressModalVisibility,
            postConfig,
            location === 'site' ? routerParams.siteId : routerParams.presetId
        );
        // If save is successful, you might want to re-allow navigation:
         setAllowNavigation(true);
        methods.reset();
    };

    // Form submission
    const onSubmit = (formData: any) => {
        console.log(formData);

        let localConfigChanges: any = [];
        let localInventoryChanges: any = [];

        // Compare partial module config with original
        let formModuleConfig = convertFormDataToPartialConfig(
            formData,
            undefined,
            location,
            config?.gbucket
        );
        localConfigChanges = comparePartialModuleConfig(
            formModuleConfig,
            config.originalModuleConfig
        );

        // Compare inventory changes
        let allSlots = localInventoryConfig.slots;
        allSlots.forEach((inventory: string) => {
            let inventoryChange: any = [];
            if (localInventoryConfig.onlyPresetSlots.includes(inventory)) {
                if (!isNotConfigured(inventory, formData, inventoryLocation)) {
                    inventoryChange = comparePartialModuleConfig(
                        convertFormDataToPartialConfig(formData, inventory, inventoryLocation),
                        {}
                    );
                } else {
                    // continue to next inventory
                    return;
                }
            } else if (
                config.originalInventoryConfig[inventory] &&
                localInventoryConfig.inventoryConfig[inventory]
            ) {
                inventoryChange = comparePartialModuleConfig(
                    convertFormDataToPartialConfig(formData, inventory, inventoryLocation),
                    config.originalInventoryConfig[inventory]
                );
            } else if (!deletedPresetSlots.includes(inventory)) {
                // brand new slot
                config.originalInventoryConfig[inventory] = createInventoryConfig(
                    formData,
                    inventory,
                    inventoryLocation
                );
                if (!localInventoryConfig.onlyPresetSlots.includes(inventory)) {
                    inventoryChange = 'new';
                }
            } else {
                // if it was previously deleted
                inventoryChange = null;
            }

            localInventoryChanges.push({
                [inventory]: inventoryChange == null ? null : inventoryChange || [],
            });
        });

        // If there are inactive slots, mark them as null
        if (localInventoryConfig.inactiveSlots?.length > 0) {
            localInventoryConfig.inactiveSlots.forEach((slot: string) => {
                localInventoryChanges.push({
                    [slot]: null,
                });
            });
        }

        // Update final config to post
        currentConfigToPost.current.moduleConfig = structuredClone(formModuleConfig);
        currentConfigToPost.current.inventoryConfig = addMetaToInventoryConfig(
            localInventoryConfig,
            structuredClone(
                changeInventoryConfig(
                    config.originalInventoryConfig,
                    localInventoryChanges,
                    inventoryLocation
                )
            ),
            formData?.Inventory_Descriptions
        );

        // If gbucket is present
        if (config?.gbucket) {
            currentConfigToPost.current.gbucketUrls =
                config?.gbucketUrls?.concat(config?.gbucket.results[0].gbucketUrls) ||
                config?.gbucket.results[0].gbucketUrls;
        }

        // Add any changed inventory descriptions
        if (formData.Inventory_Descriptions) {
            for (const key in formData.Inventory_Descriptions) {
                localConfigChanges.push({
                    slot: key,
                    description: formData.Inventory_Descriptions[key],
                    oldDescription:
                        config.originalInventoryConfig[key]?.meta?.description || '',
                    type: 'description',
                });
            }
        }

        // Mark deleted slots
        deletedPresetSlots.forEach((slot) => {
            localInventoryChanges.push({
                [slot]: 'delete',
            });
        });

        removeObjectsWithEmptyFirstKeyAndNullValue(localInventoryChanges);
        setConfigChanges(localConfigChanges);
        setInventoryChanges(localInventoryChanges);
        changeConfirmationModalVisibility();
    };

    // Programmatic form submit
    const formRef = useRef<HTMLFormElement>(null);
    const handleFormSubmit = () => {
        if (formRef.current) {
            formRef.current.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }));
        }
    };

    // Provide context
    const contextValue = useMemo(() => {
        const builderConfig = config?.builderConfig;
        const mySlots = localInventoryConfig?.slots;
        const myOnlyPresetSlots = localInventoryConfig?.onlyPresetSlots;
        const myInactiveSlots = localInventoryConfig?.inactiveSlots;

        return {
            moduleGroups,
            builderConfig,
            localModuleConfig,
            localInventoryConfig,
            location,
            slots: mySlots,
            onlyPresetSlots: myOnlyPresetSlots,
            inactiveSlots: myInactiveSlots,
            mergedConfig,
            deleteSlot,
            addSlot,
            addToLocalModuleConfig,
            addToLocalInventoryConfig,
            unsetVariable,
        };
        // eslint-disable-next-line
    }, [
        config?.builderConfig,
        localInventoryConfig,
        moduleGroups,
        localModuleConfig,
        location,
        mergedConfig,
        deleteSlot,
        addSlot,
        addToLocalModuleConfig,
        addToLocalInventoryConfig,
        unsetVariable,
        config?.gbucket,
    ]);

    // If data loading
    if (isLoading || isAppliedPresetsLoading) {
        return <Loader />;
    }

    // Confirm navigation
    const confirmNavigation = () => {
        setNavigationModalVisible(false);
        // If user chooses "Leave," allow navigation:
        setAllowNavigation(true);
        blocker.proceed(); // <-- let React Router continue to the blocked route
        setPendingPath(null);
    };

    // Cancel navigation
    const cancelNavigation = () => {
        setNavigationModalVisible(false);
        setPendingPath(null);
        // If user chooses "Stay," do nothing.
        // call blocker.reset() to stay on the current page
        blocker.reset();
    };

    return (
        <>
            <ConfiguratorContext.Provider value={contextValue}>
                <FormProvider {...methods}>
                    <Container fluid>
                        <Row>
                            <Col>
                                <h3>{siteId} configuration</h3>
                            </Col>
                            <Col className={'d-flex justify-content-end'}>
                                <PermissionButton variant="success" onClick={handleFormSubmit}>
                                    Save Changes
                                </PermissionButton>
                            </Col>
                        </Row>
                        <Row>
                            <Form onSubmit={methods.handleSubmit(onSubmit)} ref={formRef}>
                                <TabNav justify fill variant="tabs">
                                    <TabNavItem>
                                        <a
                                            href="#"
                                            className={activeTab === 'site' ? 'nav-link active' : 'nav-link'}
                                            onClick={(e) => {
                                                e.preventDefault();
                                                setActiveTab('site');
                                            }}
                                        >
                                            <i className="bi bi-sliders" /> Site
                                        </a>
                                    </TabNavItem>
                                    <TabNavItem>
                                        <a
                                            href="#"
                                            className={activeTab === 'inventory' ? 'nav-link active' : 'nav-link'}
                                            onClick={(e) => {
                                                e.preventDefault();
                                                setActiveTab('inventory');
                                            }}
                                        >
                                            <i className="bi bi-folder" /> Inventory
                                        </a>
                                    </TabNavItem>
                                </TabNav>

                                {/* Tab Content: Site */}
                                <div style={{ display: activeTab === 'site' ? 'block' : 'none' }}>
                                    <ModuleConfig location={location} />
                                </div>

                                {/* Tab Content: Inventory */}
                                <div style={{ display: activeTab === 'inventory' ? 'block' : 'none' }}>
                                    <InventoryConfig
                                        location={location === 'site' ? 'inventory' : 'preset-inventory'}
                                    />
                                </div>
                            </Form>
                        </Row>
                    </Container>
                </FormProvider>

                {/* Confirmation Modal for Saving Changes */}
                <ConfigModals
                    progressModalVisible={progressModalVisible}
                    changeProgressModalVisibility={changeProgressModalVisibility}
                    confirmationModalVisible={confirmationModalVisible}
                    changeConfirmationModalVisibility={changeConfirmationModalVisibility}
                    saveChanges={handleSaveChanges}
                    configChanges={configChanges}
                    inventoryChanges={inventoryChanges}
                    statusMessage={statusMessage}
                    isLoading={postConfigIsLoading}
                    configType={'context'}
                    setConfigChanges={() => setConfigChanges([])}
                />

                {/* Navigation Modal to confirm leaving without saving */}
                <NavigationModal
                    navigationModalVisible={navigationModalVisible}
                    setNavigationModalVisible={cancelNavigation}
                    newPath={pendingPath || ''}
                    onConfirm={confirmNavigation}
                />
            </ConfiguratorContext.Provider>
        </>
    );
};

// @ts-ignore
Configurator.whyDidYouRender = false;
export default Configurator;
