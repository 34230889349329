import React, {FC} from 'react';
import {Pie} from 'react-chartjs-2';
import {Chart as ChartJS, Tooltip, Legend, ArcElement, Title, ChartOptions} from 'chart.js';
import _ from 'lodash';

interface DeviceChartProps {
    stats: {
        device: {
            [key: string]: number;
        };
    };
    customOptions?: Partial<ChartOptions>;
}

ChartJS.register(
    Tooltip,
    Legend,
    ArcElement,
    Title
);

const DeviceChart: FC<DeviceChartProps> = ({stats, customOptions = {}}) => {
    const devices = stats.device;

    const data = {
        labels: Object.keys(devices),
        datasets: [
            {
                data: Object.values(devices),
                backgroundColor: [
                    '#FF6384',
                    '#36A2EB',
                    '#FFCE56',
                    '#4BC0C0',
                    '#9966FF',
                    '#C9CB3F'
                ],
                hoverBackgroundColor: [
                    '#FF6384',
                    '#36A2EB',
                    '#FFCE56',
                    '#4BC0C0',
                    '#9966FF',
                    '#C9CB3F'
                ]
            }
        ]
    };

    const defaultOptions: ChartOptions<'line'> = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top' as const,
            },
            title: {
                display: false,
            },
        }
    };

    // Use Lodash's merge function to deeply merge the custom options into the default options
    const options = _.merge({}, defaultOptions, customOptions);

    return (
        <div>
            {stats && <Pie data={data} options={options} width={"30%"}/>}
        </div>
    );
};

export default DeviceChart;
