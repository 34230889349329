import {ModuleDifference} from "../types";
import {
    mergeDeep,
    convertPresetName,
    searchModuleKeysForInventory,
    searchNestedObject
} from "./utils";

import React from "react";

export const comparePresets = (newPreset: string[], previousPreset: string[]) => {
    let result: any = []
    if (newPreset === previousPreset) return result
    else {
        result.push({
            moduleName: "Presets",
            variable: "",
            newValue: newPreset,
            oldValue: previousPreset,
            type: "deletedModule"
        });
        return result
    }
}


export const mergePresetConfigs = (presetConfigs: any, inventorySlot: string | undefined): any => {

    if (presetConfigs.length === 0) {
        return {};
    }
    // Initialize the result object with a copy of the first object
    let result = structuredClone({...presetConfigs[presetConfigs.length - 1]});

    // add origin to each vars in moduleConfig
    for (const moduleName in result.moduleConfig) {
        for (const key in result.moduleConfig[moduleName].vars) {
            result.moduleConfig[moduleName].vars[key] = {
                value: result.moduleConfig[moduleName].vars[key],
                origin: result.name,
            };
        }
    }

    //add origin to each vars in inventoryConfig

    if (inventorySlot) {

        for (const slot in result.inventoryConfig) {
            for (const moduleName in result.inventoryConfig[slot]) {
                for (const key in result.inventoryConfig[slot][moduleName].vars) {
                    result.inventoryConfig[slot][moduleName].vars[key] = {
                        value: result.inventoryConfig[slot][moduleName].vars[key],
                        origin: result.name,
                    };
                }
            }
        }
    }


    // Iterate through the objects in the array starting from the second one
    for (let i = presetConfigs.length - 2; i >= 0; i--) {
        const currentObject = presetConfigs[i];

        // Iterate through the modules in the current object's moduleConfig
        for (const moduleName in currentObject.moduleConfig) {
            if (!result.moduleConfig[moduleName]) {
                result.moduleConfig[moduleName] = {
                    vars: {}
                }
            }
            // Merge the vars from the current object into the result
            for (const key in currentObject.moduleConfig[moduleName].vars) {
                // Add the source object's name to each value under vars
                result.moduleConfig[moduleName].vars[key] = {
                    value: currentObject.moduleConfig[moduleName].vars[key],
                    origin: currentObject.name,
                };
            }
        }

        if (inventorySlot) {
            for (const slot in currentObject.inventoryConfig) {
                if (!result.inventoryConfig[slot]) {
                    result.inventoryConfig[slot] = {}
                }
                for (const moduleName in currentObject.inventoryConfig[slot]) {
                    if (!result.inventoryConfig[slot][moduleName]) {
                        result.inventoryConfig[slot][moduleName] = {
                            vars: {}
                        }
                    }
                    // Merge the vars from the current object into the result
                    for (const key in currentObject.inventoryConfig[slot][moduleName].vars) {
                        // Add the source object's name to each value under vars
                        result.inventoryConfig[slot][moduleName].vars[key] = {
                            value: currentObject.inventoryConfig[slot][moduleName].vars[key],
                            origin: currentObject.name,
                        };
                    }
                }
            }
        }
    }


    return result;
}


export const compareSettings =  (newCommit : any , oldCommit : any ) => {
    let result: any = []

    if(newCommit?.displayName !== oldCommit?.displayName){
        result.push({
            setting : "displayName",
            newValue: newCommit.displayName,
            oldValue: oldCommit.displayName,
        });
    }

    if(newCommit?.publisher !== oldCommit?.publisher){
        result.push({
            setting : "publisher",
            newValue: newCommit.publisher,
            oldValue: oldCommit.publisher,
        });
    }

    if(newCommit?.url !== oldCommit?.url){
        result.push({
            setting : "url",
            newValue: newCommit.url,
            oldValue: oldCommit.url,
        });
    }

    if(newCommit?.gbucketSync !== oldCommit?.gbucketSync){
        result.push({
            setting : "gbucketSync",
            newValue: newCommit.gbucketSync,
            oldValue: oldCommit.gbucketSync,
        });
    }

    //gbucket URL's if it is not there it is an empty array
    if((newCommit?.gbucketUrls || [] ).toString()  !== (oldCommit?.gbucketUrls || []).toString() ){
        result.push({
            setting : "gbucketUrls",
            newValue: newCommit?.gbucketUrls || [],
            oldValue: oldCommit.gbucketUrls || [],
        });
    }

    return result
}

export const comparePartialModuleConfig = (newModuleConfig: any, previousModuleConfig: any): ModuleDifference[] => {

    let result: ModuleDifference[] = [];

    const allModuleKeys = Object.keys(newModuleConfig).concat(Object.keys(previousModuleConfig)).filter((value, index, self) => self.indexOf(value) === index);
    for (const i in allModuleKeys) {
        const moduleName = allModuleKeys[i];
        const module1 = newModuleConfig[moduleName];
        const module2 = previousModuleConfig[moduleName];
        if (module1 && module1.vars) {
            for (const variableName in module1.vars) {
                const variable1 = module1.vars[variableName]
                if ((!!variable1 || variable1 === 0) || variable1 === false || variable1 === "") {
                    if (module2?.vars && module2.vars.hasOwnProperty(variableName)) {
                        const variable2 = module2.vars[variableName];
                        // Compare the values of the variables
                        if ((JSON.stringify(variable1) !== JSON.stringify(variable2))) {
                            // Create a result object with the module name, variable name, old value, and new value
                            const resultObj = {
                                moduleName: moduleName,
                                variable: variableName,
                                newValue: (typeof (variable1) === "number" || typeof (variable1) === "boolean") ? variable1 : (typeof (variable1) === "object") ? JSON.stringify(variable1) : variable1.toString(),
                                oldValue: (typeof (variable2) === "number" || typeof (variable2) === "boolean") ? variable2 : (typeof (variable2) === "object") ? JSON.stringify(variable2) : variable2.toString(),
                                type: typeof (variable1)
                            };
                            result.push(resultObj);

                        }
                    } else {
                        result.push({
                            moduleName: moduleName,
                            variable: variableName,
                            newValue: (typeof (variable1) === "number" || typeof (variable1) === "boolean") ? variable1 : (typeof (variable1) === "object") ? JSON.stringify(variable1) : variable1.toString(),
                            oldValue: undefined,
                            type: typeof (variable1)
                        });
                    }
                }
            }
        }
        if (module2 && module2.vars) {
            for (const variableName in module2.vars) {
                const variable2 = module2.vars[variableName]
                if ((!!variable2 || variable2 === 0) || variable2 === false || variable2 === "") {
                    if (!module1?.vars || !module1.vars.hasOwnProperty(variableName)) {
                        result.push({
                            moduleName: moduleName,
                            variable: variableName,
                            newValue: undefined,
                            oldValue: (typeof (variable2) === "number" || typeof (variable2) === "boolean") ? variable2 : (typeof (variable2) === "object") ? JSON.stringify(variable2) : variable2.toString(),
                            type: typeof (variable2)
                        });
                    }
                }
            }
        }


    }
    return result
}

export const compareInventoryConfig = (newInvConf: any, preInvConf: any) => {

    let result: any = []
    let newKeys: string [] = Object.keys(newInvConf)
    let deleted: string [] = getDeletedInventory(newInvConf, preInvConf)
    let added: string[] = getAddedInventory(newInvConf, preInvConf)
    let reverted: string[] = getRevertedInventory(newInvConf, preInvConf)


    deleted.forEach(
        (key) => result.push({
                [key]: "delete"
            }
        )
    )
    added.forEach(
        (key) => result.push({
                [key]: "new"
            }
        )
    )

    reverted.forEach(
        (key) => result.push({
                [key]: "reset"
            }
        )
    )
    newKeys.forEach(
        (key ) => {
            let inventoryChanges
            if (preInvConf[key] && newInvConf[key]) {
                inventoryChanges = comparePartialModuleConfig(newInvConf[key], preInvConf[key])
                if (inventoryChanges.length > 0) {
                    result.push({
                        [key]: inventoryChanges
                    })
                }
                if(newInvConf[key]?.meta?.description !== preInvConf[key]?.meta?.description) {
                    let change = [{
                        type: "description",
                        slot: key,
                        description: newInvConf[key]?.meta?.description,
                        oldDescription: preInvConf[key]?.meta?.description
                    }]
                    result.push({
                        [key]: change
                    })

                }
            }
        }
    )
   
    return result
}

export const addVariableOrigins = (moduleConfig: any, location: string | undefined) => {

    let result = structuredClone(moduleConfig);

    for (const moduleName in moduleConfig) {
        for (const key in moduleConfig[moduleName].vars) {
            result[moduleName].vars[key] = {
                value: moduleConfig[moduleName].vars[key],
                origin: location || "default",
            };
        }
    }
    return result
}



export const convertFormDataToPartialConfig = (formData: any, inventory: string | undefined, location: string, gbucket?: any) => {


    let config: any = {}
    for (let key in formData) {
        const moduleName = key;

        for (let varKey in formData[key].vars) {
            const variable = varKey;

            for (let val in formData[key].vars[variable]) {

                const type = getType(val)
                const inventoryName = getInventoryName(val)
                const origin = getOrigin(val);
                let value = formData[key]?.vars[varKey][type + "__" + origin + (inventoryName ? "__" + inventoryName : "")]

                //only add the variable if it is from the same origin
                if (location === origin || (gbucket && origin === "gbucket")) {
                    if ((inventoryName && inventory === inventoryName ) || (!inventory && !inventoryName )  ) {
                        if (formData[key] === "") continue

                        if ((type === "object" || type === "array") && typeof (value) === "string" && formData[key] !== "") {

                            value = JSON.parse(value)
                        }
                        if (config.hasOwnProperty(moduleName)) {
                            config[moduleName].vars[variable] = value
                        } else {
                            config[moduleName] = {
                                "vars": {
                                    [variable]: value
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    return config
}


export const createInventoryConfig = (formData: any, inventory: string, location: string) => {
    let result: any = {
        [inventory]: {}
    }
    for (let key in formData) {
        const moduleName = key
        for (let varKey in formData[key].vars) {
            const variable = varKey;

            for (let val in formData[key].vars[variable]) {
                const type = getType(val)
                const inventoryName = getInventoryName(val)
                const origin = getOrigin(val);
                let value = formData[key]?.vars[varKey][type + "__" + origin + (inventoryName ? "__" + inventoryName : "")]
                if (value === "" || (!value && value !== 0)) continue

                if (type === "object" || type === "array") {
                    try {
                        value = JSON.parse(value)
                    } catch (error) {
                        console.log(error)
                    }
                }

                if (inventoryName === inventory && origin === location) {
                    result[inventory] = {
                        ...result[inventory],
                        [moduleName]: {
                            vars: {
                                ...result[inventory][moduleName]?.vars,
                                [variable]: value
                            }
                        }
                    }
                }
            }


        }
    }
    return result[inventory]
}
export const getModuleName = (key: string) => {
    return key
}

export const getVariableName = (key: string) => {
    return key.split("__")[1]
}

export const getType = (key: string) => {
    return key.split("__")[0]
}

export const getOrigin = (key: string) => {

    //AdHeader.vars.prio.number__default
    return key.split("__")[1]
}

export const getInventoryName = (key: string) => {
    return key.split("__")[2]
}

export const changeOriginInName = (key: string, origin: string) => {
    return key.replace(getOrigin(key), origin)
}


export const changeInventoryConfig = (inventoryConfig: any, configChanges: any, location: string) => {

    let result = structuredClone(inventoryConfig);
    let inventoryKeys = Object.keys(result)
    if (inventoryKeys.length !== configChanges.length) {
        inventoryKeys.forEach(
            (invKey: string) => {
                const keyExists = configChanges.some((obj: any) => obj.hasOwnProperty(invKey));
                if (!keyExists) {
                    location === "inventory" ? result[invKey] = null : delete result[invKey]
                }

            }
        )
    }
    configChanges.forEach(
        (element: any) => {
            let inventory = Object.keys(element)[0]
            let slotChanges = element[inventory]
            if (!result.hasOwnProperty(inventory)) {
                result[inventory] = {}
            }
            if (slotChanges === null) {
                result[inventory] = null
            } else if (slotChanges.length > 0 && slotChanges !== "new") {
                result[inventory] = changeConfig(result[inventory], slotChanges)
            }
        }
    )
    return result
}

//this changes the current moduleConfig before it is posted to the api
export const changeConfig = (moduleConfig: any, configChanges: ModuleDifference[]) => {
    if (configChanges.length === 0) return moduleConfig
    const systemChangeTypes: string[] = ["newModule", "deletedModule", "newVariable", "deleteVariable"];
    for (let i = 0; i < configChanges.length; i++) {
        let configChange = configChanges[i]
        if (moduleConfig[configChange.moduleName]) {
            if (systemChangeTypes.includes(configChange.type)) break
            if (configChange.type === "object" || configChange.type === "array") {
                moduleConfig[configChange.moduleName].vars[configChange.variable] = configChange.newValue ? JSON.parse(configChange.newValue) : configChange.newValue
            } else {
                moduleConfig[configChange.moduleName].vars[configChange.variable] = configChange.newValue
            }
        } else {
            moduleConfig[configChange.moduleName] = {
                vars: {
                    [configChange.variable]: (configChange.type === "object" || configChange.type === "array") ? JSON.parse(configChange.newValue) : configChange.newValue
                }
            }
        }
    }
    return moduleConfig
}

//function to alert input


export const getDeletedInventory = (newConfig: any, previousConfig: any) => {

    let previousKeys = Object.keys(previousConfig);

    // Filter keys from previousConfig that are not present in newConfig
    const deletedKeys = previousKeys.filter(
        (key) => newConfig[key] === null && previousConfig[key] !== null
    );

    return deletedKeys || [];
};

export const getAddedInventory = (newConfig: any, previousConfig: any) => {
    let newKeys = Object.keys(newConfig)
    let previousKeys = Object.keys(previousConfig)
    return newKeys.filter(x => !previousKeys.includes(x)) || [];
}

export const getRevertedInventory = (newConfig: any, previousConfig: any): string[] => {
    const newKeys = Object.keys(newConfig);
    const previousKeys = Object.keys(previousConfig);

    const revertedKeys = newKeys.filter((key) => {
        // Check if the key was set to null in the previous config
        return previousKeys.includes(key) && previousConfig[key] === null && newConfig[key] !== null;
    });

    return revertedKeys;
};


export const saveChanges = (message: string, configData: any, setStatusMessage: any, setConfigChanges: any, changeConfirmationModalVisibility: any, changeProgressModalVisibility: any, postConfig: any, id: string | undefined) => {
    changeConfirmationModalVisibility();
    postConfig({
        id: id,
        data: {
            ...configData,
            version: "2.0",
            message: message
        }
    })
        .unwrap()
        .then((data: any) => {
            setStatusMessage("Changes are saved");
        })
        .catch((error: any) => {
            setStatusMessage(error.data.message);
        })
        .finally(() => {
            setConfigChanges([]);
        });
    changeProgressModalVisibility();
};



export const isNotConfigured = (inventory: string, formData: any, location: string) => {

    if (inventory && formData && location) {


        for (let module in formData) {
            for (let variable in formData[module].vars) {
                for (let value in formData[module].vars[variable]) {
                    const origin = getOrigin(value)
                    const formInventroy = getInventoryName(value)
                    if (origin === location && formInventroy === inventory) {
                        return false
                    }
                }
            }
        }

    }
    return true
}

export const mergeInventoryConfig = (appliedPresetsInventoryConfig: any[], inventoryConfig?: any) => {
    let result: any = {}
    for (let i = appliedPresetsInventoryConfig.length - 1; i >= 0; i--) {
        if (appliedPresetsInventoryConfig[i].inventoryConfig) {
            if (i === appliedPresetsInventoryConfig.length - 1) {
                result = appliedPresetsInventoryConfig[i].inventoryConfig
            } else {
                result = mergeDeep(result, appliedPresetsInventoryConfig[i].inventoryConfig)
            }
        }
    }
    if (inventoryConfig) {
        return mergeDeep(inventoryConfig, result);
    }
    return result
}

export const getModuleGroups = (obj: any) => {
    let keys: string[] = []

    for (let key in obj) {
        if (obj[key].modules && obj[key].modules[Object.keys(obj[key].modules)[0]].vars) {
            keys.push(key)
        } else {
            if (typeof obj[key] === 'object') {
                const result = getModuleGroups(obj[key].modules);
                if (result) {
                    keys.push(...result);
                }
            }
        }

    }
    return keys
}

export const getModuleGroupBuilderConfig = (obj: any, moduleGroup: string) => {
    for (let key in obj.modules) {
        if (key === moduleGroup) {
            return obj.modules[key]
        } else {

            const result: any = getModuleGroupBuilderConfig(obj.modules[key], moduleGroup);
            if (result) {
                return result;
            }

        }
    }
}




export const contextAddSlot = (slot: string, localInventoryConfig: any, setLocalInventoryConfig: (obj: any) => void) => {


    const onlyPresetSlots = localInventoryConfig.onlyPresetSlots.filter((item: string) => item !== slot); // Remove the slot from onlyPresetSlots
    const slots = [...localInventoryConfig.slots, slot]
    const inactiveSlots = localInventoryConfig.inactiveSlots.filter((item: string) => item !== slot);

    const newSlotManagment = {
        inventoryConfig: {
            ...localInventoryConfig.inventoryConfig,
            [slot]: {}
        },
        onlyPresetSlots: onlyPresetSlots,
        inactiveSlots: inactiveSlots,
        slots: slots
    };

    setLocalInventoryConfig(newSlotManagment);
}



export const contextDeleteSlot = (slot: string, localInventoryConfig: any, setLocalInventoryConfig: (obj: any) => void, deletedPresetSlots: string[], setDeletedPresetSlots: React.Dispatch<React.SetStateAction<string[]>>) => {
    const slots = localInventoryConfig.slots.filter((item: string) => item !== slot);
    if (localInventoryConfig?.onlyPresetSlots?.includes(slot)) {
        const updatedPresetSlots = localInventoryConfig.onlyPresetSlots.filter((item: string) => item !== slot);
        const inactiveSlots = [...localInventoryConfig.inactiveSlots, slot]
        setLocalInventoryConfig({...localInventoryConfig , slots: slots, onlyPresetSlots: updatedPresetSlots, inactiveSlots: inactiveSlots});
        setDeletedPresetSlots([...deletedPresetSlots, slot]);
        return
    }
    if (localInventoryConfig.inventoryConfig && localInventoryConfig.inventoryConfig[slot]) {
        const updatedData = {...localInventoryConfig}
        delete updatedData[slot];
        setDeletedPresetSlots([...deletedPresetSlots, slot]);
        setLocalInventoryConfig({...localInventoryConfig, slots: slots});

    }
}


export const transformVariablesArrayToStructuredObject = (variables: any, inventorySlot: string | undefined, location: string | undefined, finalModuleConfig: any, finalInventoryConfig: any, moduleKey: string) => {

    const result: any = {};
    let currentHeaderKey: any = null;

    variables.forEach((variable: any) => {
        const key = Object.keys(variable)[0];
        const value = structuredClone(variable[key]);

        // New logic for setting origin and value based on conditions
        value.origin = "default";
        if (!inventorySlot || value.configurableForInventory !== false) {
            if (location && ["site", "preset"].includes(location)) {
                value.origin = convertPresetName((finalModuleConfig && finalModuleConfig[moduleKey] && finalModuleConfig[moduleKey].vars[key] && finalModuleConfig[moduleKey].vars[key].origin) || value.origin);
                value.value = (finalModuleConfig && finalModuleConfig[moduleKey] && finalModuleConfig[moduleKey].vars[key] && finalModuleConfig[moduleKey].vars[key].value);
            }
            if (inventorySlot) {
                value.origin = convertPresetName((finalInventoryConfig && finalInventoryConfig[moduleKey] && finalInventoryConfig[moduleKey].vars[key] && finalInventoryConfig[moduleKey].vars[key].origin)
                    || convertPresetName((finalModuleConfig && finalModuleConfig[moduleKey] && finalModuleConfig[moduleKey].vars[key] && finalModuleConfig[moduleKey].vars[key].origin)
                    || value.origin));
                value.value =(finalInventoryConfig && finalInventoryConfig[moduleKey] && finalInventoryConfig[moduleKey].vars[key] &&
                finalInventoryConfig[moduleKey].vars[key].value !== undefined && finalInventoryConfig[moduleKey].vars[key].value !== null
                    ? finalInventoryConfig[moduleKey].vars[key].value
                    : (finalModuleConfig && finalModuleConfig[moduleKey] && finalModuleConfig[moduleKey].vars[key] && finalModuleConfig[moduleKey].vars[key].value));

                value.slot = inventorySlot;
            }


            if (value.type === "Header") {
                currentHeaderKey = key;
                result[currentHeaderKey] = {meta: value};
            } else if (currentHeaderKey) {
                if (!result[currentHeaderKey].meta) result[currentHeaderKey].meta = {};
                result[currentHeaderKey][key] = value;
            } else {
                result[key] = value;
            }
        }
    });

    return result;
};


export const getMergedConfig = (builderConfig: any, moduleConfig: any, inventoryConfig: any, appliedData: any, location: string, gbucket?: any) => {

    let gbucketInventoryConfig : any

    if (gbucket?.results?.[0]?.inventoryConfig) {
        gbucketInventoryConfig = gbucket.results[0].inventoryConfig
    }


    let invnetoryModuleKeys: any, result: any = {moduleConfig: {}, inventoryConfig: {}}
    let mergedPresetsModuleConfig, mergedPresetsInvnetoryConfig: any, finalModuleConfig: any, finalInventoryConfig: any

    const moduleGroups = getModuleGroups(builderConfig?.modules)


    if (appliedData && appliedData.length > 0) {
        mergedPresetsModuleConfig = mergePresetConfigs(appliedData, undefined).moduleConfig
    }


    if (moduleConfig) {
        if (mergedPresetsModuleConfig) {
            finalModuleConfig = mergeDeep(mergedPresetsModuleConfig, addVariableOrigins(moduleConfig, location))
        } else {
            finalModuleConfig = addVariableOrigins(moduleConfig, location)
        }


    }


    moduleGroups.forEach((moduleGroup: string) => {
        const moduleGroupBuilderConfig = getModuleGroupBuilderConfig(builderConfig, moduleGroup)
        result.moduleConfig[moduleGroup] = {}

        moduleGroupBuilderConfig?.modules && Object.keys(moduleGroupBuilderConfig.modules).forEach((moduleKey: any) => {
                const module = searchNestedObject(builderConfig, moduleKey)
                const variables: any = module?.vars || module.modules.Environment.vars
                result.moduleConfig[moduleGroup][moduleKey] = transformVariablesArrayToStructuredObject(variables, undefined, location, finalModuleConfig, {}, moduleKey)

            }
        )
    })

    invnetoryModuleKeys = searchModuleKeysForInventory(builderConfig, "vars");

    if (appliedData && appliedData.length > 0) {
        mergedPresetsInvnetoryConfig = mergePresetConfigs(appliedData, "dothemerge").inventoryConfig
    }


    if (inventoryConfig && inventoryConfig.slots) {


        inventoryConfig.slots.forEach(
            (slot: string) => {
                let config = addVariableOrigins(inventoryConfig.inventoryConfig[slot], location === "site" ? "inventory" : "preset-inventory")

                if (mergedPresetsInvnetoryConfig && mergedPresetsInvnetoryConfig[slot]) {
                    finalInventoryConfig = mergeDeep(mergedPresetsInvnetoryConfig[slot], config)
                } else {
                    finalInventoryConfig = config
                }
                result.inventoryConfig[slot] = {}
                invnetoryModuleKeys.forEach((moduleKey: any) => {
                        const module = searchNestedObject(builderConfig, moduleKey)
                        const variables: any = module?.vars
                        result.inventoryConfig[slot][moduleKey] = transformVariablesArrayToStructuredObject(variables, slot, location === "site" ? "inventory" : "preset-inventory", finalModuleConfig, finalInventoryConfig, moduleKey)
                    }
                )
            }
        )
        if(gbucketInventoryConfig){
            let gbucketSlots = Object.keys(gbucketInventoryConfig)
            gbucketSlots.forEach(
                (slot: string) => {
                    let config = gbucketInventoryConfig[slot]
                    if (result.inventoryConfig[slot]) {
                        result.inventoryConfig[slot] = mergeDeep(result.inventoryConfig[slot], config)
                    } else {

                        result.inventoryConfig[slot] = {}
                        invnetoryModuleKeys.forEach((moduleKey: any) => {
                                const module = searchNestedObject(builderConfig, moduleKey)
                                const variables: any = module?.vars
                                result.inventoryConfig[slot][moduleKey] = transformVariablesArrayToStructuredObject(variables, slot, "gbucket", {}, config, moduleKey)
                            }
                        )

                    }
                }
            )
        }

    }





    return result
}

export function addMetaToInventoryConfig(obj1: any, obj2: any, descriptions?: any) {
    const inventoryConfig = obj1.inventoryConfig;
    const result = { ...obj2 };

    for (const key in result) {
        if (inventoryConfig.hasOwnProperty(key) && inventoryConfig[key] !== null && result[key]!== null && inventoryConfig[key].hasOwnProperty('meta') ) {
            result[key]['meta'] = inventoryConfig[key]['meta'];
        }
    }
    if(descriptions){
        for(const inventory in descriptions){
                result[inventory]['meta'] = {}
                result[inventory]['meta']['description'] = descriptions[inventory]

        }
    }



    return result;
}

