import { Button, Modal, Form } from "react-bootstrap";
import React, { FC, useState } from "react";
import Description from "../Description";

interface TagOptionsModalProps {
    tagOptions: string;
    setTagOptions: (tagOptions: string) => void;
    show: boolean;
    setShow: () => void;
}

const TagOptionsModal: FC<TagOptionsModalProps> = ({
                                                       tagOptions,
                                                       setTagOptions,
                                                       show,
                                                       setShow,
                                                   }) => {
    // State for switches
    const [isDataPathActive, setDataPathActive] = useState(false);
    const [isDeviceIdActive, setDeviceIdActive] = useState(false);
    const [isPpidActive, setPpidActive] = useState(false);

    // Switch to control data-targeting
    const [isDataTargetingActive, setIsDataTargetingActive] = useState(false);


    const [targetingKeys, setTargetingKeys] = useState<
        { id: number; key: string }[]
    >([]);

    // Handle adding a new targeting key
    const handleAddTargetingKey = () => {
        setTargetingKeys((prevKeys) => [
            ...prevKeys,
            {
                id: Date.now(),
                key: "",
            },
        ]);
    };

    // Handle removing an existing targeting key
    const handleRemoveTargetingKey = (id: number) => {
        setTargetingKeys((prev) => prev.filter((field) => field.id !== id));
    };

    // Handle changing the key
    const handleTargetingKeyChange = (id: number, newKey: string) => {
        setTargetingKeys((prev) =>
            prev.map((field) => {
                if (field.id === id) {
                    return { ...field, key: newKey };
                }
                return field;
            })
        );
    };

    const submitForm = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault(); // Prevent page reload

        // Build array of active options
        const activeOptions: (string | null)[] = [
            isDataPathActive ? `data-path="%%data-path%%"` : null,
            isDeviceIdActive ? `data-device-id="%%data-device-id%%"` : null,
            isPpidActive ? `data-ppid="%%data-ppid%%"` : null,
        ];

        // If data-targeting switch is on, build the JSON
        if (isDataTargetingActive && targetingKeys.length > 0) {
            // Construct an object like { "age": "%%age%%", "gender": "%%gender%%" }
            const targetingObj: Record<string, string> = {};
            targetingKeys.forEach(({ key }) => {
                // The value is always %%key%%
                targetingObj[key] = `%%${key}%%`;
            });

            // Convert to JSON and wrap in single quotes: data-targeting='{"age":"%%age%%","gender":"%%gender%%"}'
            const dataTargetingStr = `data-targeting='${JSON.stringify(targetingObj)}'`;
            activeOptions.push(dataTargetingStr);
        }

        // Filter out nulls and join
        const joinedOptions = activeOptions.filter(Boolean).join(" ");

        // Save in parent state
        setTagOptions(joinedOptions);
        console.log("Submitted:", joinedOptions);

        // Close modal
        setShow();
    };

    return (
        <Modal show={show} onHide={setShow}>
            <Modal.Header closeButton>
                <Modal.Title>Modal title</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form onSubmit={submitForm}>
                    <Form.Group controlId="dataPathSwitch" className="mb-3">
                        <Form.Label className="mb-0">Page Path  <Description description="Provide the content path of your page.
                         Helps to better organize and traffic the adslot." /> </Form.Label>
                        <Form.Check
                            type="switch"
                            checked={isDataPathActive}
                            onChange={() => setDataPathActive(!isDataPathActive)}
                        />
                    </Form.Group>

                    <Form.Group controlId="deviceIdSwitch" className="mb-3">
                        <Form.Label className="mb-0">Device ID  <Description description="Provide apples IDFA or androids AAID in an app environment" /></Form.Label>
                        <Form.Check
                            type="switch"
                            checked={isDeviceIdActive}
                            onChange={() => setDeviceIdActive(!isDeviceIdActive)}
                        />
                    </Form.Group>

                    <Form.Group controlId="ppidSwitch" className="mb-3">
                        <Form.Label className="mb-0">User ID (PPID)  <Description description="In case you can provide a user id from your ecosystem
                             which is unique per user. Eg a hashed email address." />
                        </Form.Label>
                        <Form.Check
                            type="switch"
                            checked={isPpidActive}
                            onChange={() => setPpidActive(!isPpidActive)}
                        />
                    </Form.Group>

                    <div className={"border"}>
                        {/* data-targeting switch */}
                        <Form.Group controlId="dataTargetingSwitch" className="mb-3">
                            <Form.Label className="mb-0">Custom Targeting  <Description description="Additional targeting we can use." /></Form.Label>
                            <Form.Check
                                type="switch"
                                checked={isDataTargetingActive}
                                onChange={() => setIsDataTargetingActive(!isDataTargetingActive)}
                            />
                        </Form.Group>

                        {/* Only show targeting keys if isDataTargetingActive is true */}
                        {isDataTargetingActive && (
                            <div className="mb-3 p-2 rounded">
                                {targetingKeys.map((field) => (
                                    <div key={field.id} className="d-flex align-items-center mb-2">
                                        <Form.Control
                                            type="text"
                                            placeholder="Enter a key (e.g. age)"
                                            className="me-2"
                                            value={field.key}
                                            onChange={(e) =>
                                                handleTargetingKeyChange(field.id, e.target.value)
                                            }
                                        />
                                        <Button
                                            variant="danger"
                                            onClick={() => handleRemoveTargetingKey(field.id)}
                                        >
                                            Remove
                                        </Button>
                                    </div>
                                ))}
                                <Button variant="outline-primary" onClick={handleAddTargetingKey}>
                                    + Add key
                                </Button>
                            </div>
                        )}
                    </div>


                    <Modal.Footer>
                        <Button type="submit" variant="primary">
                            Add
                        </Button>
                        <Button onClick={setShow} variant="secondary">
                            Close
                        </Button>
                    </Modal.Footer>
                </form>
            </Modal.Body>
        </Modal>
    );
};

export default TagOptionsModal;
