import React, {FC, useState, useRef} from "react";
import {useConfiguratorContext} from "../../../hooks/configuratorContext";
import {Accordion, Col, Row} from "react-bootstrap";
import {searchNestedObject} from "../../../utils/utils";
import {ActiveBadge} from "../../../styles/CommonComponents";
import Description from "../../Description";
import VariableGroup from "../VariableGroup";
import ContextVariable from "./ContextVariable";

type IContextModules = {
    groupId: string
    location: string
}

const ContextModules: FC<IContextModules> = ({groupId, location}) => {
    const {builderConfig, mergedConfig} = useConfiguratorContext()
    let mergedConfigCopy: any = ["site", "preset"].includes(location) ? mergedConfig?.moduleConfig[groupId] : mergedConfig?.inventoryConfig[groupId]
    // Create a ref to the container wrapping the inputs
    const accordionBodyRefs = useRef<{ [key: string]: HTMLDivElement | null }>({});
    // Function to adjust heights of all textareas
    const adjustTextareaHeights = (moduleName: string) => {
        const bodyRef = accordionBodyRefs.current[moduleName];
        if (bodyRef) {
            // Use setTimeout to ensure DOM updates are completed
            setTimeout(() => {
                const textareas = bodyRef.querySelectorAll('textarea');
                textareas.forEach((textarea: HTMLTextAreaElement) => {
                    textarea.style.height = 'auto'; // Reset height
                    textarea.style.height = `${textarea.scrollHeight}px`; // Adjust to fit content
                });
            }, 0);
        }
    };
    return (
        <>
            <Row>
                <Col className="align-items-center">
                    <Accordion>
                        {
                            mergedConfigCopy && Object.keys(mergedConfigCopy).length > 0 ? (
                                Object.keys(mergedConfigCopy)
                                    .filter(module => {
                                        let moduleBuilderConfig = searchNestedObject(builderConfig, module)
                                        return moduleBuilderConfig?.meta?.hidden !== true
                                    })
                                    .sort((a, b) => {
                                        //TODO: create a function for this
                                        const aIsActive = mergedConfigCopy[a]?.active?.value !== undefined && mergedConfigCopy[a]?.active?.value !== null
                                            ? !!mergedConfigCopy[a]?.active?.value
                                            : !!mergedConfigCopy[a]?.active?.default;
                                        const bIsActive = mergedConfigCopy[b]?.active?.value !== undefined && mergedConfigCopy[b]?.active?.value !== null
                                            ? !!mergedConfigCopy[b]?.active?.value
                                            : !!mergedConfigCopy[b]?.active?.default;
                                        if (aIsActive === bIsActive) {
                                            return a.localeCompare(b);
                                        }
                                        if (aIsActive) {
                                            return -1;
                                        }
                                        return 1;
                                    })
                                    .map((module, index) => {
                                        const builderMeta: any = searchNestedObject(builderConfig, module)?.meta
                                        // Use the state value for isActive
                                        const isActive = mergedConfigCopy[module]?.active?.value !== undefined && mergedConfigCopy[module]?.active?.value !== null
                                            ? !!mergedConfigCopy[module]?.active?.value
                                            : !!mergedConfigCopy[module]?.active?.default;
                                        // get key
                                        if (module && module !== "meta") {
                                            return (
                                                <Accordion.Item eventKey={"" + module} key={module}>
                                                    <Accordion.Header>
                                                        <Row className={"w-35"}>
                                                            <Col xs="auto"
                                                                 className="d-flex align-items-center justify-content-start">
                                                                <ActiveBadge active={isActive}/>
                                                            </Col>
                                                            <Col xs="auto"
                                                                 className="d-flex align-items-center justify-content-start">
                                                                {builderMeta.name || module}
                                                            </Col>
                                                            <Col xs="auto"
                                                                 className="d-flex align-items-center justify-content-start">
                                                                {builderMeta.description &&
                                                                    <Description description={
                                                                        builderMeta.description
                                                                    }/>}
                                                            </Col>
                                                        </Row>
                                                    </Accordion.Header>
                                                    <Accordion.Collapse
                                                        eventKey={"" + module}
                                                        onEntered={() => adjustTextareaHeights(module)}
                                                    >
                                                        <Accordion.Body   ref={(el: any) => {
                                                            accordionBodyRefs.current[module] = el;
                                                        }}>
                                                                {
                                                                    mergedConfigCopy && mergedConfigCopy[module] && Object.keys(mergedConfigCopy[module]).map((variable: string, index: number) => {
                                                                        if (mergedConfigCopy[module][variable] && !mergedConfigCopy[module][variable].hidden)
                                                                            return (
                                                                                (!mergedConfigCopy[module][variable].meta &&
                                                                                    <ContextVariable key={module + variable}
                                                                                                     name={variable}
                                                                                                     moduleName={module}
                                                                                                     groupId={groupId}
                                                                                                     location={location}
                                                                                    />)
                                                                                || <VariableGroup key={variable}
                                                                                                  headerName={variable}
                                                                                                  variables={mergedConfigCopy[module][variable]}
                                                                                                  moduleName={module}
                                                                                                  location={location}
                                                                                                  groupId={groupId}
                                                                                />
                                                                            )
                                                                    })
                                                                }
                                                        </Accordion.Body>
                                                    </Accordion.Collapse>
                                                </Accordion.Item>
                                            );
                                        }
                                    })
                            ) : (
                                <p>{"No config found, please contact your administrator"}</p>
                            )
                        }
                    </Accordion>
                </Col>
            </Row>
        </>
    )
}

export default ContextModules