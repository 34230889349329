import React, {FC} from "react";
import {Container, Row, Col} from 'react-bootstrap';
import {useParams} from "react-router-dom";
import {useGetStatsQuery} from "../../redux/api/slothAPI";
import ActivityChart from "../ActivityChart";
import ReferrerChart from "./ReferrerChart";
import DeviceChart from "./DeviceChart";
import Loader from "../Loader";
import {StatContainer} from "../../styles/Stats";
import {FlexBox, FlexBoxItem} from "../../styles/CommonComponents";

interface Logs {
    errorRate: number;
    fillRate: number;
    inventory: {
        [key: string]: {
            errorRate: number;
            fillRate: number;
        };
    };
}

interface Stats {
    results: [{
        logs: Logs;
        referrer: { [key: string]: number };
        device: { [key: string]: number };
    }];
}

const Statistics: FC = () => {
    const routerParams = useParams<{ siteId?: string }>();
    const siteId = routerParams.siteId || "";

    const {data, isLoading} = useGetStatsQuery(siteId);

    const activitiesOptions = {
        plugins: {
            title: {
                display: false,
            }
        },
        scales: {
            y: {
                min: 0,
                display: true,
                title: {
                    display: false,
                },
            },
        }
    };

    if (isLoading) {
        return <Loader/>;
    }

    const typedData = data as Stats;
    const {logs, referrer, device} = typedData.results[0];

    return (
        <>
            <Container fluid>
                <Row className="FlexRow">
                    <Col xs="8" className="mb-3 FlexCol">
                        <StatContainer>
                            <h4>Referrers</h4>
                            <ReferrerChart stats={{referrer}}/>
                        </StatContainer>
                        <br/>
                        <StatContainer>
                            <h4>Activity</h4>
                            <ActivityChart stats={typedData.results[0]} customOptions={activitiesOptions}/>
                        </StatContainer>
                    </Col>
                    <Col sm="4" xs="12" className="mb-3">
                        <StatContainer>
                            <h4>Devices</h4>
                            <DeviceChart stats={{device}}/>
                        </StatContainer>
                    </Col>
                </Row>
                <Row>
                    <Col sm="8" xs="8" className="mb-3">
                        <StatContainer>
                            <h4>Slots</h4>
                            <FlexBox>
                                {Object.entries(logs.inventory).map(([key, value]) => (
                                    <FlexBoxItem key={key}>
                                        <h6>{key}</h6>
                                        <div><span>Error Rate:</span> {value.errorRate}%</div>
                                        <div><span>Fill Rate:</span> {value.fillRate}%</div>
                                    </FlexBoxItem>
                                ))}
                            </FlexBox>
                        </StatContainer>
                    </Col>
                    <Col sm="2" xs="2" className="mb-3">
                        <StatContainer>
                            <h4>Fill Rate</h4>
                            <p>{logs.fillRate}%</p>
                        </StatContainer>
                    </Col>
                    <Col sm="2" xs="2" className="mb-3">
                        <StatContainer>
                            <h4>Error Rate</h4>
                            <p>{logs.errorRate}%</p>
                        </StatContainer>
                    </Col>
                </Row>
            </Container>
        </>
    );
};

export default Statistics;