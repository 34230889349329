import React, {FC} from 'react';
import {useGetConfigCommitQuery} from "../../redux/api/slothAPI";
import {useParams} from "react-router-dom";
import {
    compareInventoryConfig,
    comparePartialModuleConfig,
    comparePresets,
    compareSettings
} from "utils/config-helper";
import ConfigChanges from "../configuration/ConfigChanges";
import Loader from "../Loader";
import InventoryChanges from "../inventory/InventoryChanges";

interface HistoryChangeProps {
    commitId: string
    previousCommitId: string
    service: string
}

const HistoryChange: FC<HistoryChangeProps> = ({commitId, previousCommitId, service}) => {
    const routerParams = useParams();

    const queryArgs = {
        id: routerParams?.siteId || routerParams?.presetId,
        commitId: commitId,
        service: service
    }

    const queryArgs2 = {
        id: routerParams?.siteId || routerParams?.presetId,
        commitId: previousCommitId,
        service: service
    }

    const {data: newCommit} = useGetConfigCommitQuery(queryArgs)
    const {data: oldCommit, isLoading} = useGetConfigCommitQuery(queryArgs2)
    let moduleConfigDifferences, inventoryConfigDifferences, presetsDifferences = [], settingDifferences = []

    if (newCommit && oldCommit) {
        settingDifferences = compareSettings(newCommit, oldCommit)
    }

    if (newCommit?.moduleConfig && oldCommit?.moduleConfig) {
        moduleConfigDifferences = comparePartialModuleConfig(newCommit.moduleConfig, oldCommit.moduleConfig);
    }

    if (newCommit?.inventoryConfig && oldCommit?.inventoryConfig) {
        inventoryConfigDifferences = compareInventoryConfig(newCommit.inventoryConfig, oldCommit.inventoryConfig);
    }

    if (newCommit?.presets && oldCommit?.presets && newCommit?.presets.toString() !== oldCommit?.presets.toString()) {
        presetsDifferences = comparePresets(newCommit.presets, oldCommit.presets)
    }

    if (isLoading) {
        return <Loader/>
    }



    return (
        <>
            {presetsDifferences && presetsDifferences.length > 0 &&
                <ConfigChanges presetsChanges={presetsDifferences}/>}
            {moduleConfigDifferences && moduleConfigDifferences.length > 0 &&
                <ConfigChanges configChanges={moduleConfigDifferences}/>}
            {settingDifferences && settingDifferences.length > 0 &&
                <ConfigChanges settingsChanges={settingDifferences}/>}
            {inventoryConfigDifferences && inventoryConfigDifferences.length > 0 &&
                <InventoryChanges inventoryChanges={inventoryConfigDifferences} presetsChanges={presetsDifferences}/>}
            {moduleConfigDifferences && moduleConfigDifferences?.length === 0 && inventoryConfigDifferences && inventoryConfigDifferences.length === 0
                && presetsDifferences && presetsDifferences.length === 0 &&
                settingDifferences && settingDifferences.length === 0 &&
                <p>No changes were found</p>}
        </>
    );
};

export default HistoryChange;