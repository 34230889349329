import React, {useState} from "react";
import {Button} from "react-bootstrap";
import {Modal} from "react-bootstrap";
import {useSyncGbucketMutation} from "../../redux/api/slothAPI";
import PermissionButton from "../ButtonWithPermission";


const GbucketSync: React.FC = () => {
    const [showModal, setShowModal] = useState(false);
    const [modalMessage, setModalMessage] = useState("");
    const [syncGbucket, {isLoading: isSyncing, error: syncError}] = useSyncGbucketMutation();

    const handleSync = async () => {
        try {
            await syncGbucket({}).unwrap();
            setModalMessage('Sync successful!');
        } catch {
            setModalMessage(syncError?.toString() || 'Sync failed. Please try again.');
        } finally {
            setShowModal(true);
        }
    };

    return (
        <>
            <PermissionButton onClick={handleSync} disabled={isSyncing}>
                {isSyncing ? "Syncing..." : "Sync with Gbucket"}
            </PermissionButton>

            <Modal show={showModal} onHide={() => setShowModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Sync Status</Modal.Title>
                </Modal.Header>
                <Modal.Body>{modalMessage}</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowModal(false)}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default GbucketSync;
