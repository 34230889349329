import React, {useState, useRef, useEffect, FC} from 'react';
import {Dropdown, Form, Button, CloseButton} from 'react-bootstrap';
import {useGetPublishersQuery} from '../../redux/api/slothAPI';
import {convertPublisherName} from "../../utils/utils";
import CreatePublisherModal from "./CreatePublisherModal";

interface SearchableDropdownProps {
    placeholder?: string;
    name?: string;
    isInvalid?: boolean;
    defaultValue?: string;
}

const SearchableDropdown: FC<SearchableDropdownProps> = ({placeholder, name, isInvalid, defaultValue}) => {
    const [search, setSearch] = useState('');
    const [showDropdown, setShowDropdown] = useState(false);
    const {data: publishersData} = useGetPublishersQuery();
    const [showModalPublisher, setShowModalPublisher] = useState(false);
    const [initialPublisherName, setInitialPublisherName] = useState('');


    const wrapperRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (defaultValue) {
            setSearch(defaultValue);
        }
    }, [defaultValue]);

    useEffect(() => {
        function handleClickOutside(event: MouseEvent) {
            if (wrapperRef.current && !wrapperRef.current.contains(event.target as Node)) {
                setShowDropdown(false);
            }
        }

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [wrapperRef]);

    const handleSelect = (eventKey: string | null) => {
        if (eventKey) {
            setSearch(eventKey);
            setShowDropdown(false);
        }
    };

    const clearSearch = () => {
        setSearch('');
        setShowDropdown(false);
    };

    const toggleModal = () => {
        setShowModalPublisher(!showModalPublisher);
        if (!showModalPublisher) {
            setInitialPublisherName(search);
        }
    };
    return (
        <div ref={wrapperRef} style={{position: 'relative'}}>
            <Form.Control
                type="string"
                placeholder={placeholder || "Search Publisher..."}
                name={name}
                value={convertPublisherName(search)}
                isInvalid={isInvalid}
                onChange={(e) => setSearch(e.target.value)}
                onFocus={() => setShowDropdown(true)}
                autoComplete="off"
            />
            {search && (
                <CloseButton style={{position: 'absolute', top: '50%', right: '10px', transform: 'translateY(-50%)'}} onClick={clearSearch}/>
            )}
            <Dropdown show={showDropdown} onSelect={handleSelect}>
                <Dropdown.Menu>
                    {publishersData && publishersData.results.filter(publisher =>
                        publisher.name.toLowerCase().includes(search.toLowerCase())
                    ).length > 0 ? (
                        publishersData.results.filter(publisher =>
                            publisher.name.toLowerCase().includes(search.toLowerCase())
                        ).map(publisher => (
                            <Dropdown.Item key={publisher?.name} eventKey={publisher?.name}>
                                {convertPublisherName(publisher.name)}
                            </Dropdown.Item>
                        ))
                    ) : (
                        <Dropdown.Item>
                            <Button variant="link" onClick={toggleModal}>Create Publisher</Button>
                        </Dropdown.Item>
                        )}
                </Dropdown.Menu>
            </Dropdown>
            <CreatePublisherModal show={showModalPublisher} onHide={toggleModal} initialPublisherName={search}/>
        </div>
    );
};

export default SearchableDropdown;
