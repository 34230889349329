import styled from 'styled-components';
import {Card as BootstrapCard} from 'react-bootstrap';
import {interFontStyle, robotoFontStyle} from '../../styles/Fonts';
import {device} from '../../styles/Breakpoints';

export const Card = styled(BootstrapCard)`
  ${interFontStyle}
  border-radius: 8px;
  box-shadow: 0px 1px 2px 0px #1018280F;
  box-shadow: 0px 1px 3px 0px #1018281A;
  border: none;
  font-weight: 400;

  & i {
    margin-right: 7px;
  }

  & button {
    ${robotoFontStyle}
    font-size: clamp(11px, 1vw, 16px);
    padding: 8px 20px 8px 14px;
    margin: 0.5em 0;
  }
  @media ${device.sm} {
    & button {
      padding: 8px 10px 8px 10px;
    }
    
  }

  & .card-title.h5 {
    font-size: clamp(11px, 2vw, 16px);
    color: #152536;
  }

  & p {
    margin-bottom: 0.2rem;
  }

  p::before {
    margin-top: 2em;
    background: #F4F5F9;
    font-size: 24px;
    color: #2E90FA;
    text-transform: uppercase;
    opacity: 1;
  }
`;