import React, {FC} from 'react';
import {NavLink} from 'react-router-dom';
import {TabNav, TabNavItem} from '../../styles/TabNav';

export interface TabNavProps {
    isActive?: boolean;
}

const TabNavigation : FC = ({isActive, ...props}: TabNavProps) => {



    return (
        <TabNav justify fill variant="tabs">
            <TabNavItem>
                <NavLink to="configurator" className={({isActive}) => isActive ? "nav-link active" : "nav-link"}>
                    <i className="bi bi-sliders"></i> Configuration
                </NavLink>
            </TabNavItem>
            <TabNavItem>
                <NavLink to="presets" className={({isActive}) => isActive ? "nav-link active" : "nav-link"}>
                    <i className="bi bi-stars"></i> Presets
                </NavLink>
            </TabNavItem>
            <TabNavItem >
                <NavLink to="tags" className={({isActive}) => isActive ? "nav-link active" : "nav-link"}>
                    <i className="bi bi-tags"></i> Tags
                </NavLink>
            </TabNavItem>
            <TabNavItem>
                <NavLink to="stats" className={({isActive}) => isActive ? "nav-link active" : "nav-link"}>
                    <i className="bi bi-graph-up-arrow"></i> Stats
                </NavLink>
            </TabNavItem>
            <TabNavItem>
                <NavLink to="settings" className={({isActive}) => isActive ? "nav-link active" : "nav-link"}>
                    <i className="bi bi-gear"></i>Settings
                </NavLink>
            </TabNavItem>
        </TabNav>
    );
};

export default TabNavigation;
