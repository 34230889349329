import React, {FC, useMemo} from 'react';
import {Accordion, Col, Row} from "react-bootstrap";
import Description from "../Description";
import ContextVariable from "./context/ContextVariable";

type VariableGroupProps = {
    headerName: string
    moduleName: string
    variables?: any
    location?: string
    groupId?: string
}

const VariableGroup: FC<VariableGroupProps> = ({headerName, variables, moduleName , location , groupId}) => {

    const groupedVariables = useMemo(() => {
        if (variables) {
            const { meta, ...restVariables } = variables;
            return restVariables;
        }
        return undefined;
    }, [variables]);

    const meta = useMemo(() => {
        if (variables) {
            return variables.meta;
        }
        return undefined;
    }, [variables]);

    return (
        <Row className="align-items-center">
            <Col>
                <Accordion>
                    <Accordion.Item eventKey="1">
                        <Accordion.Header>
                            <Row className={"w-25"}>
                                <Col xs="auto"
                                     className="d-flex align-items-center justify-content-start">
                                    {(meta && meta.label) || headerName}
                                </Col>
                                <Col xs="auto"
                                     className="d-flex align-items-center justify-content-start">
                                    {meta && meta.description &&
                                        <Description description={
                                            meta.description
                                        }/>}
                                </Col>
                            </Row>
                        </Accordion.Header>
                        <Accordion.Body>
                            {
                                groupedVariables && Object.keys(groupedVariables).map((variable: string, index: number) => {
                                        if (groupedVariables[variable] && !groupedVariables[variable].hidden)

                                            return (
                                                <ContextVariable key={headerName + variable}
                                                          name={variable} moduleName={moduleName} header={headerName}
                                                          isVariableGroup={true} location={location || "site"} groupId={groupId}/>
                                            )
                                    }
                                )
                            }
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            </Col>
        </Row>
    );
}

export default VariableGroup;