import React, {FC} from 'react';
import {Auth} from 'aws-amplify';
import {Button} from 'react-bootstrap';
import {LoginWrapper} from '../../styles/Login';


const OktaLogin: FC = () => {

    const handleOktaLogin = () => {
        // Redirect to Cognito Hosted UI
        Auth.federatedSignIn();
    };

    return (
        <LoginWrapper>
            <img src="/images/logo.png" alt="Logo" />

            <Button
                className="w-100"
                variant="primary"
                onClick={() => handleOktaLogin()}
            >
                Sign in with Okta
            </Button>
        </LoginWrapper>
    );
};

export default OktaLogin;
