import React from 'react';
import {Col, Container, Row} from 'react-bootstrap';
import ImportURLModal from "../ImportConfig";
import {useParams} from "react-router-dom";


export const PresetSettings = () => {


    return (
        <Container fluid>

            <h4>PresetSettings</h4>
            <Row>
                <Col xs={12}>
                    <ImportURLModal presetId={useParams().presetId} page={"Presets"} />
                </Col>
            </Row>


        </Container>
    );
}

export default PresetSettings;