import React, { FC, useState, useRef, useEffect } from 'react';
import { ChevronDown, X, ChevronRight , Pencil} from 'lucide-react';
import { useGetBuilderConfigQuery } from "../../redux/api/slothAPI";
import { getModuleGroups, getModuleGroupBuilderConfig } from "../../utils/config-helper";
import {z} from "zod"

const filterSchema = {
    boolean: z.boolean(),
    number: z
        .string()
        .min(1, { message: "This field cannot be empty" }),
    string: z
        .string()
        .min(1, { message: "This field cannot be empty" }),
    object: z.string(),
} as const;





type FilterType = any;
type FilterValue = any;

interface FilterOption {
    id: string;
    label: string;
    category: string;
    type: FilterType;
    description?: string;
    default?: any;
    options?: string[];
}

export interface AppliedFilter {
    id: string;
    label: string;
    category: string;
    type: string;
    value: any;
}

interface VarConfig {
    type: FilterType;
    default: any;
    description?: string;
    label?: string;
    sample?: any;
    hidden?: boolean;
    min?: number;
    max?: number;
}

interface ModuleMeta {
    name: string;
    description: string;
    displayPrivilege?: string[];
    importPath?: string;
    configurableForInventory?: boolean;
}

interface ModuleConfig {
    meta: ModuleMeta;
    vars: Array<Record<string, VarConfig>>;
}

interface ModuleGroupConfig {
    meta: {
        name: string;
        description: string;
        maxActiveModules?: number;
        importPath?: string;
    };
    modules: Record<string, ModuleConfig>;
}

interface BuilderConfig {
    [key: string]: ModuleGroupConfig;
}

interface FilterInputProps {
    onApplyFilters: (filters: AppliedFilter[]) => void;
}

const FilterInput: FC<FilterInputProps> = ({ onApplyFilters }) => {
    const { data: builderConf } = useGetBuilderConfigQuery();
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [inputValue, setInputValue] = useState('');
    const [appliedFilters, setAppliedFilters] = useState<AppliedFilter[]>([]);
    const [filteredOptions, setFilteredOptions] = useState<FilterOption[]>([]);
    const [selectedFilter, setSelectedFilter] = useState<FilterOption | null>(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [filterValue, setFilterValue] = useState<FilterValue>('');
    const [expandedCategories, setExpandedCategories] = useState<string[]>([]);
    const inputRef = useRef<HTMLInputElement>(null);
    const [predefinedFilters, setPredefinedFilters] = useState<FilterOption[]>([]);
    const [errors, setErrors] = useState<{ [key: string]: string }>({});


    console.log(builderConf)

    useEffect(() => {
        if (!builderConf) return;

        const filters: FilterOption[] = [];
        const moduleGroups = getModuleGroups(builderConf);

        moduleGroups.forEach(moduleGroup => {
            const config = getModuleGroupBuilderConfig(builderConf.builderConfig, moduleGroup) as ModuleGroupConfig;
            if (!config?.modules) return;

            Object.entries(config.modules).forEach(([moduleName, moduleConfig]) => {
                (moduleConfig.vars as Array<Record<string, VarConfig>>).forEach(varObj => {
                    Object.entries(varObj).forEach(([varName, varConfig]) => {
                        if (varConfig.type === 'Header' || varConfig.hidden) return;

                        filters.push({
                            id: `${moduleGroup}-${moduleName}-${varName}`,
                            label: varConfig.label || varName,
                            category: moduleConfig.meta.name,
                            type: varConfig.type,
                            default: varConfig.default,
                        });
                    });
                });
            });
        });

        setPredefinedFilters(filters);
    }, [builderConf]);

    const getAvailableFilters = () => {
        return predefinedFilters.filter(
            filter => !appliedFilters.some(af => af.id === filter.id)
        );
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        setInputValue(value);

        if (value.trim()) {
            const availableFilters = getAvailableFilters();
            const filtered = availableFilters.filter(filter =>
                filter.label.toLowerCase().includes(value.toLowerCase())
            );
            setFilteredOptions(filtered);
            setIsDropdownOpen(true);
        } else {
            setFilteredOptions([]);
            setIsDropdownOpen(false);
        }
    };

    // New: Handle clicking on an applied filter to edit it
    const handleFilterClick = (filterId: string) => {
        const filterToEdit = appliedFilters.find(f => f.id === filterId);
        if (filterToEdit) {
            setSelectedFilter(filterToEdit);
            setFilterValue(filterToEdit.value);
            setIsModalOpen(true); // Open the modal for editing
        }
    };

    const openFilterModal = (filter: FilterOption) => {
        setSelectedFilter(filter);
        setFilterValue(filter.default ?? '');
        setIsModalOpen(true);
        setIsDropdownOpen(false);
    };

    // Updated: Apply changes to an existing filter instead of adding a new one
    const handleApplyFilter = () => {
        if (!selectedFilter) return;

        try {
            filterSchema[selectedFilter.type.toLowerCase() as keyof typeof filterSchema].parse(filterValue);
            setErrors(prev => ({ ...prev, [selectedFilter.id]: "" })); // Clear any previous error

            const updatedFilters = appliedFilters.some(filter => filter.id === selectedFilter.id)
                ? appliedFilters.map(filter =>
                    filter.id === selectedFilter.id
                        ? { ...selectedFilter, value: filterValue }
                        : filter
                )
                : [...appliedFilters, { ...selectedFilter, value: filterValue }];

            setAppliedFilters(updatedFilters);
            onApplyFilters(updatedFilters); // Pass updated filters to parent

            setIsModalOpen(false);
            setInputValue('');
            setSelectedFilter(null);
            setFilterValue('');
            inputRef.current?.focus();
        } catch (error) {
            if (error instanceof z.ZodError) {
                const zodError = error as z.ZodError; // Explicitly cast error
                setErrors(prev => ({ ...prev, [selectedFilter.id]: zodError.errors[0].message }));
            }
        }
    };



    const removeFilter = (filterId: string) => {
        const updatedFilters = appliedFilters.filter(f => f.id !== filterId);

        setAppliedFilters(updatedFilters); // Update local state
        onApplyFilters(updatedFilters);    // Update parent state
    };

    const toggleDropdown = () => {
        setIsDropdownOpen(!isDropdownOpen);
        if (!isDropdownOpen) {
            const availableFilters = getAvailableFilters();
            setFilteredOptions(availableFilters);
        }
    };

    const toggleCategory = (category: string) => {
        setExpandedCategories(prev =>
            prev.includes(category)
                ? prev.filter(c => c !== category)
                : [...prev, category]
        );
    };

    const renderFilterInput = () => {
        if (!selectedFilter) return null;

        const type = selectedFilter.type.toLowerCase();

        const validateField = (value: any, type: keyof typeof filterSchema, filterId: string) => {
            try {
                filterSchema[type].parse(value); // Validate input
                setErrors(prev => ({ ...prev, [filterId]: "" })); // Clear error
            } catch (error) {
                if (error instanceof z.ZodError) {
                    const zodError = error as z.ZodError; // Explicitly cast error
                    setErrors(prev => ({ ...prev, [selectedFilter.id]: zodError.errors[0].message }));
                }
            }
        };


        switch (type) {
            case 'boolean':
                return (
                    <div className="form-check form-switch">
                        <input
                            className="form-check-input"
                            type="checkbox"
                            checked={filterValue as boolean}
                            onChange={(e) => {
                                const newValue = e.target.checked;
                                setFilterValue(newValue);
                                validateField(newValue, "boolean", selectedFilter?.id ?? ""); // Pass correct arguments
                            }}
                            id="filterSwitch"
                        />
                        <label style={{ margin: "3px 0 0 10px", textTransform: "capitalize" }} className="form-check-label" htmlFor="filterSwitch">
                            {selectedFilter.label}
                        </label>
                    </div>
                );

            case 'number':
                return (
                    <div>
                        <input
                            type="number"
                            className="form-control"
                            value={filterValue}
                            onChange={(e) => {
                                const newValue = e.target.value === "" ? "" : Number(e.target.value);
                                setFilterValue(newValue);
                                if (newValue !== "") validateField(newValue, "number", selectedFilter?.id ?? "");
                            }}

                            placeholder={`Enter ${selectedFilter.label}`}
                        />
                        {errors[selectedFilter.id] && <div className="text-danger small">{errors[selectedFilter.id]}</div>}
                    </div>
                );

            case 'string':
            case 'object':
            default:
                return (
                    <div>
                        <input
                            type="text"
                            className="form-control"
                            value={filterValue}
                            onChange={(e) => {
                                const newValue = e.target.value;
                                setFilterValue(newValue);
                                validateField(newValue, "string", selectedFilter?.id ?? "");
                            }}
                            placeholder={`Enter ${selectedFilter.label}`}
                        />
                        {errors[selectedFilter.id] && <div className="text-danger small">{errors[selectedFilter.id]}</div>}
                    </div>
                );
        }
    };

    const availableFilters = getAvailableFilters();
    const displayFilters = filteredOptions.length > 0 ? filteredOptions : availableFilters;

    return (
        <div className="w-100">
            <div className="d-flex align-items-center border rounded p-2 bg-white shadow-sm position-relative">
                <button
                    onClick={toggleDropdown}
                    className="btn btn-light btn-sm d-flex align-items-center me-2 flex-shrink-0"
                >
                    Filters <ChevronDown className="ms-1" size={16} />
                </button>

                <div className="d-flex align-items-center overflow-hidden flex-grow-1">
                    <div className="d-flex align-items-center flex-nowrap overflow-hidden">
                        {appliedFilters.map((filter) => (
                            <span
                                key={filter.id}
                                className="d-flex align-items-center bg-light rounded px-2 py-1 text-secondary me-2 flex-shrink-0"
                            >

                                <span
                                    className="text-truncate"
                                    onClick={() => handleFilterClick(filter.id)} // New: Enable click-to-edit
                                    style={{cursor: 'pointer'}}
                                >
                                                                        <Pencil style={{ margin: "1px 5px 5px 5px" }}  size={16}/>

                                    {filter.category + ': ' + filter.label}: {filter.value.toString()}
                                </span>
                                                                                                <button
                                                                                                    onClick={() => removeFilter(filter.id)}
                                                                                                    className="btn btn-link btn-sm p-0 ms-1 text-secondary flex-shrink-0"
                                                                                                    style={{textDecoration: 'none'}}
                                                                                                    aria-label="Remove filter"
                                                                                                >
                                    <X size={14}/>
                                </button>

                            </span>
                        ))}

                        <input
                            ref={inputRef}
                            type="text"
                            value={inputValue}
                            onChange={handleInputChange}
                            className="form-control-plaintext p-0"
                            style={{
                                minWidth: '60px',
                                fontSize: '0.875rem',
                                flex: '1 1 auto'
                            }}
                            placeholder={appliedFilters.length === 0 ? "Search filters..." : ""}
                        />
                    </div>
                </div>
            </div>

            {isDropdownOpen && availableFilters.length > 0 && (
                <div className="position-absolute mt-1 bg-white border rounded shadow"
                     style={{ width: '16rem', zIndex: 1000, maxHeight: '400px', overflowY: 'auto' }}>
                    <div className="py-1">
                        {displayFilters
                            .reduce((acc: { category: string; items: FilterOption[] }[], filter) => {
                                const categoryGroup = acc.find(g => g.category === filter.category);
                                if (categoryGroup) {
                                    categoryGroup.items.push(filter);
                                } else {
                                    acc.push({ category: filter.category, items: [filter] });
                                }
                                return acc;
                            }, [])
                            .map(group => (
                                <div key={group.category} className="border-bottom">
                                    <button
                                        className="d-flex align-items-center w-100 px-3 py-2 bg-light border-0"
                                        onClick={() => toggleCategory(group.category)}
                                        style={{ cursor: 'pointer' }}
                                    >
                                        <ChevronRight
                                            size={16}
                                            style={{
                                                transform: expandedCategories.includes(group.category)
                                                    ? 'rotate(90deg)'
                                                    : 'rotate(0deg)',
                                                transition: 'transform 0.2s ease',
                                                marginRight: '8px'
                                            }}
                                        />
                                        <span className="fw-medium">{group.category}</span>
                                    </button>
                                    {expandedCategories.includes(group.category) && (
                                        <div className="pb-2">
                                            {group.items.map(filter => (
                                                <button
                                                    key={filter.id}
                                                    onClick={() => openFilterModal(filter)}
                                                    className="btn btn-link text-start w-100 px-4 py-1 text-secondary"
                                                    style={{
                                                        fontSize: '0.875rem',
                                                        textDecoration: 'none',
                                                        fontWeight: 'normal'
                                                    }}
                                                >
                                                    {filter.label}
                                                    {filter.description && (
                                                        <small className="d-block text-muted">
                                                            {filter.description}
                                                        </small>
                                                    )}
                                                </button>
                                            ))}
                                        </div>
                                    )}
                                </div>
                            ))}
                    </div>
                </div>
            )}

            {isModalOpen && (
                <>
                    <div className="modal-backdrop fade show" />
                    <div className="modal d-block">
                        <div className="modal-dialog">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <div>
                                        <h5 className="modal-title">{selectedFilter?.label}</h5>
                                        {selectedFilter?.description && (
                                            <small className="text-muted d-block">
                                                {selectedFilter.description}
                                            </small>
                                        )}
                                    </div>
                                    <button
                                        type="button"
                                        className="btn-close"
                                        onClick={() => setIsModalOpen(false)}
                                    />
                                </div>
                                <div className="modal-body">
                                    {renderFilterInput()}
                                </div>
                                <div className="modal-footer">
                                    <button
                                        type="button"
                                        className="btn btn-secondary"
                                        onClick={() => setIsModalOpen(false)}
                                    >
                                        Cancel
                                    </button>
                                    <button
                                        type="button"
                                        className="btn btn-primary"
                                        onClick={handleApplyFilter}
                                        disabled={filterValue === ''}
                                    >
                                        Apply Filter
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )}
        </div>
    );
};

export default FilterInput;