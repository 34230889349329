import React, {FC, useState} from "react";
import {NavLink, Link, Outlet, useParams} from "react-router-dom";
import {Container, Col, Nav, Row} from "react-bootstrap";
import HistoryModal from "../history/HistoryModal";
import {TabNav, TabNavItem} from "../../styles/TabNav";
import {Button} from "../../styles/Buttons";
import {convertPresetName} from "../../utils/utils";


const Preset: FC = () => {
    // Getting site ID from route and RTK query hooks
    const routerParams = useParams();
    // show and close pop up for confirmation and changes
    const [showHistory, setShowHistory] = useState(false);
    const changeHistoryVisibility = () => setShowHistory(!showHistory);


    return (
        <>
            <HistoryModal showHistory={showHistory} setShowHistory={changeHistoryVisibility} configId={routerParams.presetId} service="preset"/>
            <Container className="mt-3" fluid>
                <Row>
                    <Col>
                        <Nav>
                            <Link to={"/presets"}><Button variant="outline-secondary"><i
                                className="bi bi-arrow-left"></i>Back</Button></Link>
                        </Nav>
                    </Col>
                    <Col className="d-flex justify-content-center">
                        <h3>{convertPresetName(routerParams.presetId || "preset")}</h3>
                    </Col>
                    <Col className="d-flex justify-content-end">
                        <Button variant="outline-secondary" onClick={changeHistoryVisibility} className="m-1"><i
                            className="bi bi-clock-history"></i>View Log History</Button>
                    </Col>
                </Row>
                <Row>
                    <Col sm={12} md={12} lg={12} xs={12}>
                        <TabNav justify fill variant="tabs">
                            <TabNavItem>
                                <NavLink to="configurator" className={({isActive}) => isActive ? "nav-link active" : "nav-link"}>
                                    <i className="bi bi-sliders"></i> Configuration
                                </NavLink>
                            </TabNavItem>
                            <TabNavItem className="w-100">
                                <NavLink className={({isActive}) => isActive ? "nav-link active" : "nav-link"}
                                         to="settings">
                                    <i className="bi bi-tags"></i>Settings
                                </NavLink>
                            </TabNavItem>

                        </TabNav>
                    </Col>
                </Row>
            </Container>
            <Outlet/>
        </>
    );
};

export default Preset;
