import React, {FC, useEffect, useState} from 'react';
import {Col, Container, Row, Form, Modal, Spinner, Alert, Accordion} from 'react-bootstrap';
import {useGetSiteConfigQuery, usePutSiteConfigMutation} from "../../redux/api/slothAPI";
import {useParams} from "react-router-dom";
import Loader from "../Loader";
import SearchableDropdown from "../publishers/SearchableDropdown";
import {Button} from "../../styles/Buttons";
import ImportURLModal from "../ImportConfig";
import {convertPublisherName} from "../../utils/utils";
import PermissionButton from "../ButtonWithPermission";
import {useEnvConfig} from "../../redux/hooks";

const SiteSettings: FC = () => {
    const routerParams = useParams();
    const queryArgs: any = {
        id: routerParams?.siteId
    };

    const {data, isLoading, isError: isGetError} = useGetSiteConfigQuery(queryArgs);
    const [putSiteData, {isLoading: putSiteDataIsLoading, isError, isSuccess, reset}] = usePutSiteConfigMutation();
    const siteId = useParams().siteId;
    const [show, setShow] = useState(false);
    const [checked, setChecked] = useState(false);
    const [gbucketUrls, setGbucketUrls] = useState<string[]>([]);

    const gbucketSyncEnabled = useEnvConfig('GBUCKET_SYNC') === "true";


    useEffect(() => {
        if (data) {
            setChecked(data.gbucketSync || false); // Ensure checked is always a boolean
        }
    }, [data]);

    useEffect(() => {
        if (data && data.gbucketUrls) {
            setGbucketUrls(data.gbucketUrls);
        }
    }, [data]);

    const handleClose = () => {
        setShow(false);
        reset();
    };

    const handleSwitchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setChecked(e.target.checked);
    };

    const handleShow = () => setShow(true);

    const removeUrl = (index: number) => {
        const updatedUrls = gbucketUrls.filter((_, idx) => idx !== index);
        setGbucketUrls(updatedUrls);
    };

    let [statusMessage, setStatusMessage] = useState("");

    if (isLoading) {
        return <Loader/>;
    }

    const updateGbucketUrls = (newUrls: string[]) => {
        setGbucketUrls(newUrls);
    };

    const handleSubmit = (event: any) => {
        event.preventDefault();
        handleShow();
        const form = event.currentTarget;
        const displayName = form.elements.displayName.value;
        const publisher = form.elements.publisher.value + ".publisher.js";
        const domain = form.elements.domain.value;
        const gbucketSync = checked;

        const siteData: any = {
            displayName: displayName,
            publisher: publisher,
            url: domain,
            logo: "",
            gbucketSync: gbucketSync,
            gbucketUrls: gbucketUrls
        };
        putSiteData({id: siteId, data: siteData})
            .unwrap()
            .then(() => {
                setStatusMessage("Changes saved successfully");
            })
            .catch((err) => {
                setStatusMessage(err.data.message);
            });
    };

    if (isGetError) {
        return (
            <div> "There has been the following error: "
                {isError ? isError.toString() : null}
            </div>
        );
    } else {
        return (
            <Container fluid>
                <Form autoComplete="off" id="siteSettings" className="m-1" onSubmit={handleSubmit}>
                    <Row>
                        <Col className="d-flex justify-content-end align-items-center">
                            {gbucketSyncEnabled &&
                            <ImportURLModal
                              siteId={siteId}
                              page={"Site"}
                              gbucketUrls={gbucketUrls}
                              updateGbucketUrls={updateGbucketUrls}
                            />
                            }
                            <PermissionButton className="ms-2" variant="success" onClick={handleShow}>Save Changes</PermissionButton>
                        </Col>
                    </Row>
                    <Row className="mb-3">
                        <Form.Group as={Col} lg xl={6}>
                            <Form.Label>Display Name</Form.Label>
                            <Form.Control
                                name="displayName"
                                placeholder={"Display Name"}
                                defaultValue={data && (data.displayName || data.name)}
                            />
                        </Form.Group>
                    </Row>
                    <Row className="mb-3">
                        <Form.Group as={Col} lg xl={6}>
                            <Form.Label>Publisher</Form.Label>
                            <SearchableDropdown
                                name="publisher"
                                placeholder="Publisher name"
                                defaultValue={data && (convertPublisherName(data.publisher))}
                            />
                        </Form.Group>
                    </Row>
                    <Row className="mb-3">
                        <Form.Group as={Col} lg xl={6}>
                            <Form.Label>Domain</Form.Label>
                            <Form.Control
                                name="domain"
                                placeholder="Domain"
                                defaultValue={data && (data.url)}
                            />
                        </Form.Group>
                    </Row>

                    {gbucketSyncEnabled &&
                    <Form.Group as={Row} lg className="mb-3 switch-row">
                      <Form.Label column sm={6}>
                        <Row className="d-flex align-items-center">
                          <Col className="col-auto name-col">Gbucket Sync</Col>
                          <Col className="col-auto">
                            <Form.Check
                              name="gbucketSync"
                              type="switch"
                              id="custom-switch"
                              label=""
                              checked={checked}
                              onChange={handleSwitchChange}
                            />
                          </Col>
                          <Col className="col-auto">
                              {checked &&
                              <Alert className="m-0" key={"danger"} variant={"danger"}>
                                If this is enabled, the site will be synced with gbucket
                              </Alert>
                              }
                          </Col>
                        </Row>
                      </Form.Label>
                    </Form.Group>
                    }
                    <Modal onHide={handleClose} show={show}>
                        <Modal.Header closeButton>
                            <Modal.Title>Confirm Changes</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            {putSiteDataIsLoading && <Spinner animation="border" variant="primary"/>}
                            {isError && <p>Error saving changes: {statusMessage}</p>}
                            {isSuccess && <p>Changes saved successfully</p>}
                            {!isError && !isSuccess && !putSiteDataIsLoading && <p>Are you sure you want to save the changes?</p>}
                        </Modal.Body>
                        <Modal.Footer>
                            {!isSuccess && (
                                <>
                                    <Button variant="secondary" onClick={handleClose}>{isError ? 'Close' : 'Cancel'}</Button>
                                    {!isError && <PermissionButton variant="success" type="submit" form="siteSettings">Save Changes</PermissionButton>}
                                </>
                            ) || <Button variant="secondary" onClick={handleClose}>Close</Button>}
                        </Modal.Footer>
                    </Modal>

                </Form>
                {gbucketSyncEnabled && gbucketUrls.length > 0 && (
                    <Accordion className="mb-5" defaultActiveKey="1">
                        <Accordion.Item eventKey="1">
                            <Accordion.Header>
                                Show/Hide Gbucket URLs
                            </Accordion.Header>
                            <Accordion.Body>
                                <Row>
                                    {gbucketUrls.map((url, index) => (
                                        <Col key={index} xs={12} className="mb-2">
                                            <div className="d-flex align-items-center">
                                                <div className="flex-grow-1">{url}</div>
                                                <Button variant="danger" onClick={() => removeUrl(index)}>Remove</Button>
                                            </div>
                                        </Col>
                                    ))}
                                </Row>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                )}

            </Container>
        );
    }
};
export default SiteSettings;
