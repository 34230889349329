import React, {FC, ReactElement} from 'react';
import {Row, Col, OverlayTrigger, Tooltip} from 'react-bootstrap';
import {Button} from 'react-bootstrap';
import {InitialsBadge} from '../../styles/CommonComponents';
import {showInitials, convertConfigName, convertPresetName} from "../../utils/utils";
import {Link} from "react-router-dom";
import {CustomListGroup} from "../../styles/Sites/SiteView";
import ActivityChart from "../../components/ActivityChart";


interface SiteCardProps {
    publisher?: string,
    id?: string,
    name: string,
    preset?: boolean,
    DeleteButton?: ReactElement,
    displayIndex?: number,
    stats?: any,
    moduleConfig?: Record<string, any>;
    presets?: string[];
}

const SiteListElement: FC<SiteCardProps> = ({displayIndex, id, name, publisher, preset, DeleteButton, stats, moduleConfig, presets}) => {

    const moduleConfigCount = moduleConfig ? Object.keys(moduleConfig).length : 0;
    const presetsCount = presets ? presets.length : 0;

    const renderPresetsTooltip = (props: any) => (
        <Tooltip id="presets-tooltip" {...props}>
            {presets && presets.length > 0 ? presets.join(', ') : 'No presets'}
        </Tooltip>
    );

    const renderModulesTooltip = (props: any) => (
        <Tooltip id="modules-tooltip" {...props}>
            {moduleConfig && Object.keys(moduleConfig).length > 0
                ? Object.keys(moduleConfig).map(moduleName => (
                    <div key={moduleName}>
                        {moduleName}: {moduleConfig[moduleName].vars.active ? 'Active' : 'Inactive'}
                    </div>
                ))
                : 'No modules'
            }
        </Tooltip>
    );

    return (
        <>
            <CustomListGroup className={`index-${displayIndex}`}>
                <CustomListGroup.Item>
                    <Row className="d-flex justify-content-between align-items-center">
                        <Col {...(stats ? {md: 6, lg: 6, sm: 6, xs: 6} : {})} className="d-flex align-items-center">
                            <InitialsBadge className="me-2">{showInitials(name)}</InitialsBadge>
                            <Link style={{color: 'black', display: 'block', width: '100%', textAlign: 'left'}}
                                  to={preset ? "/presets/" + id + "/configurator" : "/site/" + id + "/configurator"}>
                                {(!preset && convertConfigName(name)) || convertPresetName(name)}
                            </Link>
                        </Col>
                        {stats &&
                        <Col md={5} lg={5} sm={5} xs={4} className="statsText">
                          <Row>
                            <Col xs={2}>
                              <OverlayTrigger placement="top" delay={{show: 250, hide: 400}} overlay={renderPresetsTooltip}>
                                <span>{presetsCount}</span>
                              </OverlayTrigger>
                            </Col>
                            <Col xs={2}>
                              <OverlayTrigger placement="top" delay={{show: 250, hide: 400}} overlay={renderModulesTooltip}>
                                <span>{moduleConfigCount}</span>
                              </OverlayTrigger>
                            </Col>
                            <Col xs={2}>{stats.logs.errorRate}%</Col>
                            <Col xs={2}>{stats.logs.fillRate}%</Col>
                            <Col xs={4}><ActivityChart stats={stats}/></Col>
                          </Row>
                        </Col>
                        }
                        <Col {...(stats ? {md: 1, sm: 3} : {})} xs={2} className="d-flex justify-content-end align-items-center">
                            <div className="d-flex align-items-center">
                                <Link to={preset ? "/presets/" + id : "/site/" + id + "/configurator"}>
                                    <Button variant="primary"><i className="bi bi-pencil"></i></Button>
                                </Link>
                                {DeleteButton}
                            </div>
                        </Col>
                    </Row>
                </CustomListGroup.Item>
            </CustomListGroup>
        </>
    );
};
export default SiteListElement;