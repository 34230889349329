import styled from 'styled-components';
import {interFontStyle} from '../styles/Fonts';

export const Alert = styled.div`

  ${interFontStyle}
  border-radius: 10px;
  padding: 20px;
  margin: 20px 20px 40px 20px;


  ${props => {
    switch (props.role) {
      case 'success':
        return `
          background: #d1e7dd;
          color: #155724;
        `;
      case 'error':
        return `
          background: #f8d7da;
          color: #721c24;
        `;
            // Add more cases for other variants
      default:
        return '';
    }
  }}
`;
